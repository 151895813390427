import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { url } from '../../utils/url';

export const UsersTable = ({ rows, openUserHandler }) => {
  return (
    <>
      {!!rows?.length ? (
        <TableContainer component={Paper} sx={{ width: '100%', mb: 5, mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ maxWidth: '250px' }}>ФИО</TableCell>
                <TableCell sx={{ maxWidth: '250px' }}>Роль</TableCell>
                <TableCell>Email</TableCell>
                <TableCell sx={{ maxWidth: '250px' }}>Телефон</TableCell>
                <TableCell>Статус</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows?.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={openUserHandler}
                >
                  <TableCell
                    sx={{ maxWidth: '250px' }}
                    component="th"
                    scope="row"
                  >
                    Смирнов Сергей Александрович
                  </TableCell>
                  <TableCell sx={{ maxWidth: '250px' }}>
                    Администратор
                  </TableCell>
                  <TableCell>sergeev.am@mail.ru</TableCell>
                  <TableCell sx={{ maxWidth: '250px' }}>
                    +7 (999) 443-22-75
                  </TableCell>
                  <TableCell>
                    <Typography color="primary">Активный</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography
          sx={{
            textAlign: 'center',
            width: '550px',
            display: 'block',
            mx: 'auto',
            py: 4,
          }}
        >
          Пользователи не найдены
        </Typography>
      )}
    </>
  );
};
