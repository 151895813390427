import React, { useEffect, useState } from 'react';
import { useApi } from '../../../hooks/useApi';
import { ActionsGetAll } from '../../../api/adminAnalyticsApi';

import {
  CircularProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { add, format, parseISO } from 'date-fns';

export const AdminActionsStatisticsTable = () => {
  const [actions, setActions] = useState([]);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const ActionsGetAllApi = useApi(ActionsGetAll);
  useEffect(() => {
    getAllActionsHandler();
  }, [page, orderBy]);

  const getAllActionsHandler = async () => {
    const result = await ActionsGetAllApi.sendRequest({
      limit: 15,
      offset: page - 1,
    });
    setActions(result?.data);
    setTotalCount(result?.totalCount);
  };

  return !ActionsGetAllApi?.loading ? (
    <>
      <Paper>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Имя </TableCell>
              <TableCell align="left">
                Количество сохраненных шаблонов{' '}
              </TableCell>
              <TableCell align="left">
                Количество скачивания данных для диссертаций{' '}
              </TableCell>
              <TableCell align="left">
                Количество скачивания данных для текущей работы
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {actions?.map((row) => (
              <TableRow
                key={row.userName}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{row.userName}</TableCell>

                <TableCell component="th" scope="row">
                  {row?.actions?.find((a) => a?.key == 'SaveTemplate')?.value
                    ? row?.actions?.find((a) => a?.key == 'SaveTemplate')
                        ?.value +
                      '(' +
                      format(
                        add(
                          parseISO(
                            row?.actions?.find((a) => a?.key == 'SaveTemplate')
                              ?.lastActionDate,
                          ),
                          {
                            hours: 3,
                          },
                        ),
                        'dd.MM.yyyy HH:mm:ss',
                      ) +
                      ')'
                    : '-'}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?.actions?.find((a) => a?.key == 'DownloadResultDisser')
                    ?.value
                    ? row?.actions?.find(
                        (a) => a?.key == 'DownloadResultDisser',
                      )?.value +
                      '(' +
                      format(
                        add(
                          parseISO(
                            row?.actions?.find(
                              (a) => a?.key == 'DownloadResultDisser',
                            )?.lastActionDate,
                          ),
                          {
                            hours: 3,
                          },
                        ),
                        'dd.MM.yyyy HH:mm:ss',
                      ) +
                      ')'
                    : '-'}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?.actions?.find((a) => a?.key == 'DownloadResultWork')
                    ?.value
                    ? row?.actions?.find((a) => a?.key == 'DownloadResultWork')
                        ?.value +
                      '(' +
                      format(
                        add(
                          parseISO(
                            row?.actions?.find(
                              (a) => a?.key == 'DownloadResultWork',
                            )?.lastActionDate,
                          ),
                          {
                            hours: 3,
                          },
                        ),
                        'dd.MM.yyyy HH:mm:ss',
                      ) +
                      ')'
                    : '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Pagination
        sx={{ mt: 3 }}
        count={Math.ceil(totalCount / 15) || 1}
        onChange={(event, value) => {
          setPage(value);
        }}
        variant="outlined"
        shape="rounded"
      />
    </>
  ) : (
    <CircularProgress />
  );
};
