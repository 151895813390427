import React, { useEffect, useMemo, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Card,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import { FilterFields } from './FilterFields';
import styles from './Filter.module.scss';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { ActionApprove } from '../Modal/ActionApprove';
import { SaveSearchTemplateForm } from '../SaveSearchTemplate/SaveSearchTemplateForm';
import { BigFilter } from './BigFilter';
import { useApi } from '../../hooks/useApi';
import {
  getAllUserTemplates,
  insertSearchFilterTemplate,
} from '../../api/templatesApi';
import { useSelector } from 'react-redux';
import { getMe } from '../../api/sechenovIdApi';
import { useCookies } from 'react-cookie';
import { jsonToGraphQlQuery } from '../../utils/jsonToGraphQlQuery';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CreateEvent } from '../../api/adminAnalyticsApi';
import { getUserRole } from '../../utils/getUserRole';
import { url } from '../../utils/url';

export const SmallFilter = ({
  closeFilter,
  searchHandler,
  openBigFilterHandler,
  setOpenBigFilterHandler,
  loading,
  patientCount,
  clearRows,
  clearFilterExportDatahandler,
  filterData,
}) => {
  const [filter, setFilter] = useState(filterData);
  const [template, setTemplate] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [saveTemplateModal, setSaveTemplateModal] = useState(false);
  const [clearFilterModal, setClearFilterModal] = useState(false);

  const [execludeClientId, setExecludeClientId] = useState();
  const [execludeClientIdArray, setExecludeClientIdArray] = useState([]);
  const saveTemplateApi = useApi(insertSearchFilterTemplate);
  const getAllUserTemplatesApi = useApi(getAllUserTemplates);
  const [renderedFilter, setRenderedFilter] = useState(uuidv4());
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [templatesList, setTemplatesList] = useState([]);
  const userApi = useApi(getMe);
  const [user, setUser] = useState({});

  const CreateEventApi = useApi(CreateEvent);
  const [bigFilterModal, setBigFilterModal] = useState({
    open: false,
    closeAction: () => {
      setRenderedFilter(false);

      setBigFilterModal({ ...bigFilterModal, open: false });
      setTimeout(() => {
        setRenderedFilter(uuidv4());
      }, 2500);
    },
  });
  useEffect(() => {
    setBigFilterModal({ ...bigFilterModal, open: openBigFilterHandler });
  }, [openBigFilterHandler]);

  useEffect(() => {
    setOpenBigFilterHandler(bigFilterModal?.open);
  }, [bigFilterModal]);

  useEffect(() => {
    if (cookies?.token) {
      userApi.sendRequest(cookies.token).then((result) => {
        setUser(result);
        getAllUserTemplatesApi
          .sendRequest({
            filter: {
              userId: result.sub,
            },
            limit: 99999,
            offset: 0,
          })
          .then((response) => setTemplatesList(response?.data || []));
      });
    }
  }, []);

  const [currentFilterData, setCurrentFilterData] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let f = searchParams.get('f');
    setRenderedFilter(false);
    if (f) {
      f = JSON.parse(f);
      setSearchParams('');
      setCurrentFilterData(f);
      searchHandler(f, []);
      setTimeout(() => {
        setRenderedFilter(uuidv4());
      }, 2500);
    }
  }, [searchParams]);

  useEffect(() => {
    setFilter(JSON.parse(JSON.stringify(filterData)));
    setTimeout(() => {
      setRenderedFilter(uuidv4());
    }, 2500);
  }, [currentFilterData]);

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      if (e.target.value) {
        setExecludeClientId('');
        setExecludeClientIdArray([...execludeClientIdArray, +e.target.value]);
      }

      // put the login here
    }
  };
  const navigate = useNavigate();
  const otherFiltersFields = () => {
    return [
      <FormControl fullWidth key={'select-saved-template'} sx={{ mb: 2 }}>
        <InputLabel id={`simple-name-label-template`}>
          Выберите шаблон
        </InputLabel>
        <Select
          labelId={`simple-name-label-template`}
          id={`simple-name-template`}
          disabled={loading}
          value={selectedTemplate?.id}
          label="Выберите шаблон"
          onChange={({ target: { value } }) => {
            const { filter } = templatesList.find((i) => i.id == value);
            navigate(url.search + '?f=' + JSON.stringify(filter));
          }}
        >
          {templatesList.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
      <>
        <TextField
          key={'client-id-exclude'}
          label="Исключить по ID клиента"
          value={execludeClientId}
          sx={{ mb: 2 }}
          onKeyDown={keyPress}
          type="number"
          onChange={({ target: { value } }) => setExecludeClientId(+value)}
          fullWidth
        />
        <div style={{ widht: '100%', display: 'block', marginTop: '7px' }}>
          {execludeClientIdArray.map((item, index) => (
            <Chip
              key={index}
              label={item}
              sx={{ mr: 1 }}
              onDelete={() =>
                setExecludeClientIdArray(
                  execludeClientIdArray.filter((it) => it !== item),
                )
              }
            />
          ))}
        </div>
      </>,
    ];
  };
  const buttons = ({ fullWidth, size }) => {
    return [
      <LoadingButton
        color="primary"
        key={uuidv4()}
        fullWidth={fullWidth}
        sx={{ mb: 1, textTransform: 'none' }}
        size={size}
        variant="contained"
        loading={loading}
        disabled={!!!Object.keys(currentFilterData)?.length}
        onClick={() => {
          searchHandler(currentFilterData, execludeClientIdArray);

          CreateEventApi.sendRequest({
            userId: user.sub,
            userName:
              user.family_name && user.given_name && user.middle_name
                ? user.family_name +
                  ' ' +
                  user.given_name +
                  ' ' +
                  user.middle_name
                : user.email,
            role: getUserRole(user.role),
            text_event: 'Выполнение поиска по фильтру',
            enum_event: 'Search',
          });
          setBigFilterModal({ ...bigFilterModal, open: false });
        }}
      >
        Поиск
      </LoadingButton>,
      <LoadingButton
        color="secondary"
        loading={loading}
        key={uuidv4()}
        fullWidth={fullWidth}
        sx={{ mb: 1, textTransform: 'none' }}
        size={size}
        disabled={!Object.keys(currentFilterData)?.length}
        variant="contained"
        onClick={() => {
          setDisabled(true);
          setSaveTemplateModal(true);
        }}
      >
        Сохранить шаблон поиска
      </LoadingButton>,
      <LoadingButton
        color="inherit"
        fullWidth={fullWidth}
        key={uuidv4()}
        sx={{ mb: 1, textTransform: 'none' }}
        size={size}
        variant="text"
        loading={loading}
        onClick={() => {
          setDisabled(false);
          setClearFilterModal(true);
          clearFilterExportDatahandler();
        }}
      >
        Сбросить фильтр
      </LoadingButton>,
    ];
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '80vh',
          marginTop: '30px',
        }}
      >
        <Card
          sx={{
            maxHeight: '75%',
            mb: 3,
            overflow: 'visible',
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{ p: 2 }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography as="p" variant="h6">
              Фильтр
            </Typography>
            <IconButton
              sx={{ ml: 'auto' }}
              color="primary"
              onClick={() => closeFilter()}
            >
              <ArrowBackOutlinedIcon />
            </IconButton>
          </Stack>
          <Box sx={{ p: 2 }}>{otherFiltersFields().map((item) => item)}</Box>
          {renderedFilter ? (
            filter
              ?.filter((item) => item?.params?.some((p) => p.showInSmall))
              .map((data, index) => (
                <Accordion key={data?.key + index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={data?.key}
                    id={data?.key}
                  >
                    <Typography>{data?.name}</Typography>
                  </AccordionSummary>

                  {/* 
    } */}
                  <AccordionDetails>
                    {data?.params
                      ?.filter((p) => p.showInSmall)
                      ?.map((param, index) => (
                        <div key={param?.key + index}>
                          <FilterFields
                            key={param?.key}
                            value={
                              currentFilterData?.find((i) => i.key == param.key)
                                ?.value
                            }
                            anyValue={
                              currentFilterData?.find((i) => i.key == param.key)
                                ?.anyValue
                            }
                            param={param}
                            apiLoading={loading}
                            parentKey={data?.key}
                            currentFilterData={currentFilterData}
                            changeCurrentFilterData={(data) =>
                              setCurrentFilterData(data)
                            }
                            disableMe={
                              param.key?.indexOf('<название анализа>') != -1 &&
                              !currentFilterData.some(
                                (cd) => cd?.key == 'MedicalDocuments_DocTitle',
                              )
                            }
                          />
                        </div>
                      ))}
                  </AccordionDetails>
                </Accordion>
              ))
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
              }}
            >
              <CircularProgress />
            </div>
          )}
        </Card>

        <div className={styles?.Filter__SmallFilter__Footer}>
          {buttons({ fullWidth: true, size: 'large' }).map((button) => button)}
          <LoadingButton
            color="secondary"
            fullWidth
            sx={{ mb: 0, textTransform: 'none' }}
            size="large"
            variant="contained"
            loading={loading}
            startIcon={<TuneOutlinedIcon />}
            onClick={() => {
              setRenderedFilter(false);
              setCurrentFilterData(currentFilterData);
              setBigFilterModal({ ...bigFilterModal, open: true });
              setTimeout(() => {
                setRenderedFilter(uuidv4());
              }, 2500);
            }}
          >
            Расширенный фильтр
          </LoadingButton>
        </div>
      </div>

      {/* save search template modal */}
      <ActionApprove
        closeAction={() => setSaveTemplateModal(false)}
        successAction={() => {
          saveTemplateApi
            .sendRequest({
              ...template,
              file_name: template?.name,
              userId: user.sub,
              patientCount,
              filter: currentFilterData,
              graphql_query: `query ${jsonToGraphQlQuery(
                currentFilterData,
                0,
                0,
              )}`,
            })
            .then((result) => {
              CreateEventApi.sendRequest({
                userId: user.sub,
                userName:
                  user.family_name && user.given_name && user.middle_name
                    ? user.family_name +
                      ' ' +
                      user.given_name +
                      ' ' +
                      user.middle_name
                    : user.email,
                role: getUserRole(user.role),
                text_event: 'Сохранение поискового шаблона - ' + template?.name,
                enum_event: 'SaveTemplate',
              });
            });
          setTemplate({});
          setSaveTemplateModal(false);
        }}
        closeActionButtonText=""
        showCloseButton={false}
        modalVariant="primary"
        open={saveTemplateModal}
        disabledButton={
          !(template?.name && template?.description && template?.purpose)
        }
        alertMessages={{
          success: 'Шаблон успешно сохранен',
          error: '',
        }}
        successActionButtonText="Сохранить"
        successActionButtonColor="secondary"
        successActionButtonVariant="contained"
        title="Сохранение шаблона"
      >
        <SaveSearchTemplateForm
          state={template}
          changeState={(data) => {
            setDisabled(!(data?.title && data?.description));
            setTemplate(data);
          }}
        />
      </ActionApprove>
      {/* Clear filter */}
      <ActionApprove
        closeAction={() => setClearFilterModal(false)}
        successAction={() => {
          setRenderedFilter(false);
          setCurrentFilterData([]);
          clearRows();
          setClearFilterModal(false);

          setTimeout(() => {
            setRenderedFilter(uuidv4());
          }, 2500);
        }}
        closeActionButtonText="Отмена"
        open={clearFilterModal}
        disabledButton={false}
        alertMessages={{
          success: 'Параметры фильтра были очищены',
          error: '',
        }}
        successActionButtonText="Сбросить"
        title="Подтвердите сброс фильтра"
      >
        <Alert color="warning">Все данные параметров будут удалены</Alert>
      </ActionApprove>

      {bigFilterModal.open && (
        <BigFilter
          filterData={filterData}
          open={bigFilterModal.open}
          renderedFilter={renderedFilter}
          styles={styles}
          buttons={buttons}
          otherFiltersFields={otherFiltersFields}
          closeAction={bigFilterModal.closeAction}
          currentFilterData={currentFilterData}
          setCurrentFilterData={(data) => {
            setCurrentFilterData(data);
          }}
        />
      )}
    </>
  );
};
