import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

export const ExportForm = ({ state, changeState, user, totalCount }) => {
  return (
    <div>
      <Typography as="p" sx={{ fontWeight: 'bold', mb: 2 }}>
        Выгрузка результатов поиска
      </Typography>
      <Typography>Пациентов: {totalCount}</Typography>
      <Typography sx={{ mb: 2 }}>
        Исследователь: {user.family_name} {user.given_name} {user.middle_name}
      </Typography>
      <Card sx={{ background: '#30489F14', boxShadow: 'none', mb: 2 }}>
        <CardContent>
          <Typography>
            Укажите тему работы, если выгрузка данных происходит для
            использования в научной статье или публикуемой научной работе.
          </Typography>
        </CardContent>
      </Card>
      <TextField
        label="Тема работы"
        variant="outlined"
        fullWidth
        size="small"
        sx={{ mb: 2 }}
        value={state?.title}
        onChange={({ target: { value } }) =>
          changeState({ ...state, title: value })
        }
      />
      <FormControl sx={{ mb: 3 }}>
        <FormLabel id="demo-row-radio-buttons-group-label">Тег</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={state?.purpose}
        >
          <FormControlLabel
            value="Текущая работа"
            control={
              <Radio
                onChange={({ target: { value } }) =>
                  changeState({ ...state, purpose: value })
                }
              />
            }
            label="Текущая работа"
          />
          <FormControlLabel
            value="Научная статья"
            control={
              <Radio
                onChange={({ target: { value } }) =>
                  changeState({ ...state, purpose: value })
                }
              />
            }
            label="Научная статья"
          />
          <FormControlLabel
            value="Диссертация"
            control={
              <Radio
                onChange={({ target: { value } }) =>
                  changeState({ ...state, purpose: value })
                }
              />
            }
            label="Диссертация"
          />
        </RadioGroup>
      </FormControl>
      <Card sx={{ background: '#9F373014', boxShadow: 'none', mb: 2 }}>
        <CardContent>
          <Stack direction="row" spacing={2} alignItems="start">
            <Checkbox
              checked={state?.agree}
              onChange={({ target: { checked } }) =>
                changeState({ ...state, agree: checked })
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <div>
              <Typography sx={{ fontWeight: 'bold' }}>
                Я ознакомлен с правилами использования выборки обезличенных
                клинических данных пациентов
              </Typography>
              <Typography>
                Уважаемый {user.family_name} {user.given_name}{' '}
                {user.middle_name}! Вы скачиваете выборку обезличенных
                клинических данных пациентов из базы данных Сеченовского
                Университета. Интеллектуальные права на данную информацию
                принадлежат Сеченовскому Университету и могут быть использованы
                только в рамках Вашей трудовой деятельности. Согласно приказу
                №041-05 от 26.06.2019 «Об утверждении формы согласия на
                обработку персональных данных», обезличенные медицинские данные
                о состоянии здоровья пациента, результаты обследования и
                лечения, иные результаты полученной клинической практики, могут
                быть использованы исключительно для осуществления
                научно-исследовательской деятельности Университета и проведения
                научных и клинических исследований, их опубликования в научных
                изданиях, а также с целью использования в обучении. Передача
                клинических данных третьим лицам может быть осуществлена только
                по договору между Сеченовским Университетом и другим юридическим
                лицом
              </Typography>
            </div>
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
};
