// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.rs-picker-daterange-menu {
  z-index: 9999 !important;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-top: 16.5px !important;
  padding-bottom: 16.5px !important;
}
.rs-picker-toggle:hover {
  border-color: #111 !important;
  
}
.rs-picker-toggle-clean {
  top: 17px !important;
}
/* .rs-picker-toggle-value {
  color: #818181 !important;
} */
.rs-picker-toggle {
  border-color: #c4c4c4 !important;
  
}
.rs-picker-toggle:hover  .rs-picker-toggle-value {
  color: #818181 !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,wBAAwB;AAC1B;AACA;EACE,8BAA8B;EAC9B,iCAAiC;AACnC;AACA;EACE,6BAA6B;;AAE/B;AACA;EACE,oBAAoB;AACtB;AACA;;GAEG;AACH;EACE,gCAAgC;;AAElC;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n.rdrDefinedRangesWrapper {\n  display: none !important;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.rs-picker-daterange-menu {\n  z-index: 9999 !important;\n}\n.rs-picker-default .rs-picker-toggle.rs-btn-lg {\n  padding-top: 16.5px !important;\n  padding-bottom: 16.5px !important;\n}\n.rs-picker-toggle:hover {\n  border-color: #111 !important;\n  \n}\n.rs-picker-toggle-clean {\n  top: 17px !important;\n}\n/* .rs-picker-toggle-value {\n  color: #818181 !important;\n} */\n.rs-picker-toggle {\n  border-color: #c4c4c4 !important;\n  \n}\n.rs-picker-toggle:hover  .rs-picker-toggle-value {\n  color: #818181 !important;\n}\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
