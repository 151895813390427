import {
  Alert,
  AlertTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { checkTemplateName } from '../../api/templatesApi';
import { useSelector } from 'react-redux';
import { getMe } from '../../api/sechenovIdApi';
import { useCookies } from 'react-cookie';

export const SaveSearchTemplateForm = ({ state, changeState }) => {
  const [nameIsExist, setNameIsExist] = useState(false);
  const checkTemplateNameApi = useApi(checkTemplateName);
  const [user, setUser] = useState({});
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const userApi = useApi(getMe);

  useEffect(() => {
    if (cookies?.token) {
      userApi.sendRequest(cookies.token).then((result) => setUser(result));
    }
  }, []);
  return (
    <>
      <TextField
        variant="outlined"
        label="Название шаблона"
        value={state.name}
        fullWidth
        sx={{ mb: 2 }}
        size="small"
        error={nameIsExist}
        helperText={nameIsExist ? 'Имя шаблона должно быть уникально' : ''}
        onChange={({ target: { value } }) => {
          if (user.sub) {
            checkTemplateNameApi
              .sendRequest({
                name: value,
                userId: user.sub,
              })
              .then((result) => setNameIsExist(result));
          }
          changeState({ ...state, name: value });
        }}
      />
      <TextField
        variant="outlined"
        label="Описание"
        value={state.description}
        fullWidth
        sx={{ mb: 2 }}
        size="small"
        onChange={({ target: { value } }) =>
          changeState({ ...state, description: value })
        }
      />
      <FormControl sx={{ mb: 3 }}>
        <FormLabel id="demo-row-radio-buttons-group-label">Тег</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={state?.purpose}
        >
          <FormControlLabel
            value="Текущая работа"
            control={
              <Radio
                onChange={({ target: { value } }) =>
                  changeState({ ...state, purpose: value })
                }
              />
            }
            label="Текущая работа"
          />
          <FormControlLabel
            value="Научная статья"
            control={
              <Radio
                onChange={({ target: { value } }) =>
                  changeState({ ...state, purpose: value })
                }
              />
            }
            label="Научная статья"
          />
          <FormControlLabel
            value="Диссертация"
            control={
              <Radio
                onChange={({ target: { value } }) =>
                  changeState({ ...state, purpose: value })
                }
              />
            }
            label="Диссертация"
          />
        </RadioGroup>
      </FormControl>
      <Alert color="primary">
        <AlertTitle>Рекомендация</AlertTitle>
        <Typography sx={{ width: '90%' }}>
          Пожалуйста, заполняйте форму корректно для того, чтобы вы смогли
          комфортно ориентироваться по вашему списку сохраненных шаблонов
          <br />
          <br />
          Пример заполнения: Тестовая выборка клинических данных урологических
          пациентов
        </Typography>
      </Alert>
    </>
  );
};
