import { configureStore } from '@reduxjs/toolkit';
import alertReducer from './alert.store';
import userReducer from './user.store';

export default configureStore({
  reducer: {
    alerts: alertReducer,
    user: userReducer,
  },
});
