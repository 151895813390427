import React from 'react';
import { Header } from '../../Header/Header';
import { Button, Container, Stack, Typography } from '@mui/material';
import { AlertsList } from '../../AlertsList/AlertsList';
import { useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { Helmet } from 'react-helmet';

export const MainLayout = ({
  title,
  children,
  actionText,
  action,
  loading,
  disabledButton,
}) => {
  const alerts = useSelector((state) => state.alerts);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>С.У. - {title || 'Поисковый модуль'}</title>

        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      </Helmet>
      <Header />
      <Container maxWidth="xl">
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          sx={{ mt: 2 }}
        >
          <Typography as="h1" variant="h5">
            {title}
          </Typography>
          {!!actionText && (
            <LoadingButton
              disabled={disabledButton}
              variant="contained"
              color="secondary"
              sx={{ textTransform: 'none' }}
              onClick={action}
              loading={loading}
            >
              {actionText}
            </LoadingButton>
          )}
        </Stack>
        {children}
      </Container>
      <AlertsList alerts={alerts} />
    </>
  );
};
