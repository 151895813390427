import axios from 'axios';

export const getExistDatatable = async ({
  userId,
  fileDbId,
  file_name,
  graphql_query,
}) => {
  try {
    const { data } = await axios.post(
      'https://meddata.sechenov.ru/v1/data_loader/get_exist_datatable',
      {
        userId,
        fileDbId,
        file_name,
        graphql_query,
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
export const searchResultFileExport = async ({
  graphql_query,
  file_name,
  userId,
}) => {
  try {
    const { data } = await axios.post(
      'https://meddata.sechenov.ru/v1/data_loader/datatable',
      {
        graphql_query,
        file_name,
        userId,
      },
    );

    return data;
  } catch (error) {
    throw error;
  }
};
