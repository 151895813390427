import { TextField } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import { DateRangePicker } from 'react-date-range';
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from 'react-dates';

export const DatePickerUI = ({ onChange, label, value }) => {
  const [focused, setFocused] = useState('');
  return (
    <DateRangePicker
      startDate={value?.startDate || null} // momentPropTypes.momentObj or null,
      startDateId={label + '_start'} // PropTypes.string.isRequired,
      endDate={value?.endDate || null} // momentPropTypes.momentObj or null,
      endDateId={label + 'end'} // PropTypes.string.isRequired,
      onDatesChange={({ startDate, endDate }) => {
        onChange({
          startDate: startDate ? new Date(startDate) : null,
          endDate: endDate ? new Date(endDate) : null,
        });
      }} // PropTypes.func.isRequired,
      focusedInput={focused || null} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={(focusedInput) => setFocused(focusedInput)} // PropTypes.func.isRequired,
    />
    // <>
    //    <TextField
    //     label={label}
    //     InputLabelProps={{ shrink: true }}
    //     // value={
    //     //   dateRangeCreated?.startDate &&
    //     //   format(dateRangeCreated.startDate, 'dd.MM.yyyy') +
    //     //     ' - ' +
    //     //     dateRangeCreated?.endDate &&
    //     //   format(dateRangeCreated.endDate, 'dd.MM.yyyy')
    //     // }
    //     onClick={() => {
    //       setOpenField(!openField);
    //     }}
    //   />
    //   {openField && (
    //     <div
    //       style={{
    //         position: 'absolute',
    //         background: '#fff !important',
    //         zIndex: 99,
    //         marginTop: '60px',
    //         display: 'block',
    //         maxWidth: '100%',
    //         boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    //       }}
    //     >
    //       <DateRangePicker
    //         ranges={[dateRangeCreated]}
    //         preventSnapRefocus={false}
    //         calendarFocus="forwards"
    //         direction="vertical"
    //         displayMode="date"
    //         editableDateInputs={true}
    //         moveRangeOnFirstSelection={false}
    //         showDateDisplay={false}
    //         showMonthAndYearPickers={true}
    //         showMonthArrow={true}
    //         showPreview={false}
    //         retainEndDateOnFirstSelection={true}
    //         onChange={(ranges) => {
    //           setDateRangeCreated(ranges.selection);
    //         }}
    //       />
    //     </div>
    //   )}
    // </>
  );
};
