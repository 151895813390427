// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_Header__Logo__oZ3-t {
  width: 56px;
}
.Header_Header__Logo__oZ3-t img {
  width: 100%;
}
.Header_Header__Navigation__Normal__JF7I5 {
  padding: 12px;
  border-radius: 24px;
  display: block;
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}
.Header_Header__Navigation__Normal__JF7I5:hover {
  color: #ccc;
  text-decoration: none;
}
.Header_Header__Navigation__Active__LO9zi {
  padding: 12px;
  border-radius: 24px;
  display: block;
  background-color: rgb(255, 255, 255);
  color: #303E9F;
  text-decoration: none;
  font-size: 14px;
}
.Header_Header__Logout__9J-H\\+ {
  background: #3E4CAD !important;
  border-radius: 0 !important;
  height: 90px;
  width: 90px;
}
.Header_Header__Logout__9J-H\\+:hover {
  background: #1d276c !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AACI;EACI,WAAA;AAAR;AACQ;EACI,WAAA;AACZ;AAGQ;EACI,aAAA;EACA,mBAAA;EACA,cAAA;EACA,2CAAA;EACA,WAAA;EACA,qBAAA;EACA,eAAA;AADZ;AAEY;EACI,WAAA;EACA,qBAAA;AAAhB;AAGQ;EACI,aAAA;EACA,mBAAA;EACA,cAAA;EACA,oCAAA;EACA,cAAA;EACA,qBAAA;EACA,eAAA;AADZ;AAII;EACI,8BAAA;EACA,2BAAA;EACA,YAAA;EACA,WAAA;AAFR;AAGQ;EACI,8BAAA;AADZ","sourcesContent":[".Header {\n    &__Logo {\n        width: 56px;\n        img {\n            width: 100%;\n        }\n    }\n    &__Navigation {\n        &__Normal {\n            padding: 12px;\n            border-radius: 24px;\n            display: block;\n            background-color: rgba(255, 255, 255, 0.15);\n            color: #fff;\n            text-decoration: none;\n            font-size: 14px;\n            &:hover {\n                color: #ccc;\n                text-decoration: none;\n            }\n        }\n        &__Active {\n            padding: 12px;\n            border-radius: 24px;\n            display: block;\n            background-color: rgba(255, 255, 255, 1);\n            color: #303E9F;\n            text-decoration: none;\n            font-size: 14px;\n        }\n    }\n    &__Logout {\n        background: #3E4CAD !important;\n        border-radius: 0 !important;\n        height: 90px;\n        width: 90px;\n        &:hover {\n            background: #1d276c !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header__Logo": `Header_Header__Logo__oZ3-t`,
	"Header__Navigation__Normal": `Header_Header__Navigation__Normal__JF7I5`,
	"Header__Navigation__Active": `Header_Header__Navigation__Active__LO9zi`,
	"Header__Logout": `Header_Header__Logout__9J-H+`
};
export default ___CSS_LOADER_EXPORT___;
