// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActionApprove_ActionApprove__apEU7 {
  overflow: auto;
  justify-content: center;
}
.ActionApprove_ActionApprove__Box__a0IrR {
  width: 690px;
  height: auto;
  margin: 4% auto;
  background: #fff;
  border: none;
  outline: none;
}
.ActionApprove_ActionApprove__Box__HeaderTitle__aRPZ3 {
  background: #DFE1ED;
  padding: 10px 20px;
  font-size: 20px;
  color: #131415;
}
.ActionApprove_ActionApprove__Box__Content__\\+zrhY {
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/ActionApprove.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,uBAAA;AACJ;AAAI;EACI,YAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;AAER;AADQ;EACI,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;AAGZ;AADQ;EACI,aAAA;AAGZ","sourcesContent":[".ActionApprove{\n    overflow: auto;\n    justify-content: center;\n    &__Box {\n        width: 690px;\n        height: auto;\n        margin: 4% auto;\n        background: #fff;\n        border: none;\n        outline: none;\n        &__HeaderTitle {\n            background: #DFE1ED;\n            padding: 10px 20px;\n            font-size: 20px;\n            color: #131415\n        }\n        &__Content {\n            padding: 20px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ActionApprove": `ActionApprove_ActionApprove__apEU7`,
	"ActionApprove__Box": `ActionApprove_ActionApprove__Box__a0IrR`,
	"ActionApprove__Box__HeaderTitle": `ActionApprove_ActionApprove__Box__HeaderTitle__aRPZ3`,
	"ActionApprove__Box__Content": `ActionApprove_ActionApprove__Box__Content__+zrhY`
};
export default ___CSS_LOADER_EXPORT___;
