import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout/MainLayout';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { getAllUsersList } from '../api/sechenovIdApi';
import { useApi } from '../hooks/useApi';
import { useCookies } from 'react-cookie';
import { UsersTable } from '../components/UsersTable/UsersTable';
import { ActionApprove } from '../components/Modal/ActionApprove';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

export const UsersPage = () => {
  const [openUserModal, setOpenUserModal] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const api = useApi(getAllUsersList);
  useEffect(() => {
    // api.sendRequest({ token: cookies.token });
  }, []);
  return (
    <MainLayout
      title="Список пользователей"
      action={() => console.log('action users')}
      actionText="Выгрузка в csv"
    >
      <Paper sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={{ px: 2 }}>
              <Stack direction="row" spacing={2}>
                <TextField
                  label="Поиск по ФИО или email"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
                <Button
                  startIcon={<AddOutlinedIcon />}
                  variant="outlined"
                  sx={{ width: '350px' }}
                  size="small"
                  onClick={() => setOpenUserModal(true)}
                >
                  Добавить пользователя
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <UsersTable
          rows={[{}, {}, {}, {}]}
          openUserHandler={() => setOpenUserModal(true)}
        />
        <ActionApprove
          closeAction={() => setOpenUserModal(false)}
          successAction={() => {
            setOpenUserModal(false);
          }}
          closeActionButtonText="Отмена"
          showCloseButton={true}
          modalVariant="secondary"
          open={openUserModal}
          disabledButton={false}
          alertMessages={{
            success: 'Пользователь успешно добавлен',
            error: '',
          }}
          successActionButtonText="Сохранить"
          successActionButtonColor="secondary"
          successActionButtonVariant="contained"
          title="Карточка пользователя"
        >
          <Typography as="p" variant="h5" sx={{ mb: 3 }}>
            Смирнов Сергей Александрович
          </Typography>
          <Stack direction="row" spacing={2} sx={{ mb: 2, width: '100%' }}>
            <TextField label="ФИО" fullWidth />
            <TextField label="Роль" fullWidth />
          </Stack>
          <Stack direction="row" spacing={2} sx={{ mb: 2, width: '100%' }}>
            <TextField label="Email" fullWidth />
            <TextField label="Телефон" fullWidth />
          </Stack>
          <Stack direction="row" spacing={2} sx={{ mb: 2, width: '100%' }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Статус</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={10}
                label="Статус"
                onChange={() => console.log('asd')}
              >
                <MenuItem value={10}>Активен</MenuItem>
                <MenuItem value={20}>Не активен</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Заблокирован
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={20}
                label="Заблокирован"
                onChange={() => console.log('asd')}
              >
                <MenuItem value={10}>Заблокирован</MenuItem>
                <MenuItem value={20}>Не заблокирован</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </ActionApprove>
      </Paper>
    </MainLayout>
  );
};
