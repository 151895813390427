import React, { useEffect, useState } from 'react';
import { useApi } from '../../../hooks/useApi';
import {
  AggStatsEnumIndicators,
  AggStatsGetAll,
  AggStatsYears,
} from '../../../api/adminAnalyticsApi';
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { TabContext, TabList, TabPanel } from '@mui/lab';

export const AdminGeneralStatistics = () => {
  const [years, setYears] = useState([]);

  const [activeYear, setActiveYear] = useState(new Date().getFullYear());
  const [yearsTableData, setYearsTableData] = useState([]);

  const AggStatsYearsApi = useApi(AggStatsYears);
  const AggStatsGetAllApi = useApi(AggStatsGetAll);
  const changeActiveYear = (event, newValue) => {
    setActiveYear(newValue);
    AggStatsGetAllApi.sendRequest(newValue).then((result) =>
      setYearsTableData(result),
    );
  };

  useEffect(() => {
    AggStatsYearsApi.sendRequest().then((result) => setYears(result));
    AggStatsGetAllApi.sendRequest(activeYear).then((result) =>
      setYearsTableData(result),
    );
  }, []);

  const [aggStatsEnumIndicators, setAggStatsEnumIndicators] = useState(null);
  const aggStatsEnumIndicatorsApi = useApi(AggStatsEnumIndicators);

  useEffect(() => {
    aggStatsEnumIndicatorsApi
      .sendRequest()
      .then((result) => setAggStatsEnumIndicators(result));
  }, []);
  return !AggStatsYearsApi.loading || !AggStatsGetAllApi.loading ? (
    <>
      <Paper sx={{ p: 2, mb: 3 }}>
        <Stack direction="row" spacing={2} alignItems="flex-start">
          <Box sx={{ p: 2, maxWidth: '280px' }}>
            <Typography as="h3" variant="h5">
              {aggStatsEnumIndicators?.totEvents}
            </Typography>
            <Typography sx={{ fontSize: '15px' }}>
              Общее количество запросов
            </Typography>
          </Box>
          <Box sx={{ p: 2, maxWidth: '280px', ml: 3 }}>
            <Typography as="h3" variant="h5">
              {aggStatsEnumIndicators?.totDownloadResult}
            </Typography>
            <Typography sx={{ fontSize: '15px' }}>
              Количество скачанных файлов
            </Typography>
          </Box>
          <Box sx={{ p: 2, maxWidth: '280px', ml: 3 }}>
            <Typography as="h3" variant="h5">
              {aggStatsEnumIndicators?.totDownloadResultDisser}
            </Typography>
            <Typography sx={{ fontSize: '15px' }}>
              Количество скачанных файлов для диссертаций
            </Typography>
          </Box>
          <Box sx={{ p: 2, maxWidth: '280px', ml: 3 }}>
            <Typography as="h3" variant="h5">
              {aggStatsEnumIndicators?.totSaveTemplate}
            </Typography>
            <Typography sx={{ fontSize: '15px' }}>
              Количество сохраненных запросов
            </Typography>
          </Box>
        </Stack>
      </Paper>

      {/* Агригация дат */}
      <Paper>
        <TabContext value={activeYear} sx={{ width: '100%' }}>
          <TabList
            onChange={changeActiveYear}
            aria-label="lab API tabs example"
            sx={{ width: '100%' }}
          >
            {years?.map((year, index) => (
              <Tab key={index} label={year} value={year} />
            ))}
          </TabList>
          {years?.map((y, i) => (
            <TabPanel key={i} value={y} sx={{ px: 0 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Месяц</TableCell>
                    <TableCell align="left">Количество запросов</TableCell>
                    <TableCell align="left">
                      Количество скачанных файлов
                    </TableCell>
                    <TableCell align="left">
                      Количество скачанных файлов для диссертаций
                    </TableCell>
                    <TableCell align="left">
                      Количество сохраненных шаблонов
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {yearsTableData.map((row) => (
                    <TableRow
                      key={row.month}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.month}
                      </TableCell>
                      <TableCell align="left">{row.countEvents}</TableCell>
                      <TableCell align="left">
                        {row.countDownloadResult}
                      </TableCell>
                      <TableCell align="left">
                        {row.countDownloadResultDisser}
                      </TableCell>
                      <TableCell align="left">
                        {row.countSaveTemplate}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TabPanel>
          ))}
        </TabContext>
      </Paper>
    </>
  ) : (
    <CircularProgress />
  );
};
