import React, { useEffect, useMemo, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout/MainLayout';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Button, Grid, Pagination } from '@mui/material';
import { SmallFilter } from '../components/Filter/SmallFilter';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { AlertsList } from '../components/AlertsList/AlertsList';
import { ActionApprove } from '../components/Modal/ActionApprove';
import { ExportForm } from '../components/ExportForm/ExportForm';
import { SearchResult } from '../components/SearchResult/SearchResult';
import { jsonToGraphQlQuery } from '../utils/jsonToGraphQlQuery';
import { Navigate, useSearchParams } from 'react-router-dom';
import { url } from '../utils/url';
import { useCookies } from 'react-cookie';
import { useApi } from '../hooks/useApi';
import { searchResultFileExport } from '../api/fileExportApi';
import { result } from 'lodash';
import { getMe } from '../api/sechenovIdApi';
import { downloadTxtFile, downloadURI } from '../utils/downloadURI';
import filterDataJson from '../components/Filter/filter.json';
import { Sticky, StickyContainer } from 'react-sticky';
import { CreateEvent } from '../api/adminAnalyticsApi';
import { getUserRole } from '../utils/getUserRole';

const FILMS_QUERY = gql`
  query MyQuery {
    __typename ## Placeholder value
  }
`;
export const SearchPage = () => {
  const [page, setPage] = useState(1);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [user, setUser] = useState({});
  let [gqlSearch, { loading, error, data }] = useLazyQuery(FILMS_QUERY);
  const [filterOpen, setFilterOpen] = useState(true);
  const [currentFilterDataToExport, setCurrentFilterDataToExport] = useState(
    [],
  );
  const [orderBy, setOrderBy] = useState([]);
  const filterData = useMemo(() => filterDataJson, []);
  const [disabled, setDisabled] = useState(false);
  const [exportData, setExportData] = useState({ title: '', agree: false });
  const [openBigFilterHandler, setOpenBigFilterHandler] = useState(false);
  const [seacrhFilterData, setSeacrhFilterData] = useState([]);
  const [meddocTitle, setMeddocTitle] = useState('');
  const [queryResult, setQueryResult] = useState({});
  const [execludeClientIdArray, setExecludeClientIdArray] = useState([]);
  const searchResultFileExportApi = useApi(searchResultFileExport);
  const userApi = useApi(getMe);
  const CreateEventApi = useApi(CreateEvent);

  const replaceQueryString = ({ json, queryString }) => {
    if (queryString?.includes('<название анализа>')) {
      const meddoc = filterData
        .find((fd) => fd?.key == 'MedicalDocuments(isInstrReaserch: true)')
        ?.params?.find((p) => p.key == 'MedicalDocuments_DocTitle')
        ?.defaultData?.find(
          (md) =>
            md[0] ==
            json.find((cd) => cd?.key == 'MedicalDocuments_DocTitle')?.value,
        );
      queryString = queryString.replaceAll('<название анализа>', meddoc[1]);
      setMeddocTitle(meddoc[1]);
    }
    return queryString;
  };
  useEffect(() => {
    if (cookies?.token) {
      userApi.sendRequest(cookies.token).then((result) => {
        setUser(result);
      });
    }
  }, []);
  const [modal, setModal] = useState(false);
  const searchHandler = async (
    json,
    limit,
    offset,
    execludeClientIdArray,
    orderBy,
  ) => {
    let queryString = jsonToGraphQlQuery(
      json,
      limit,
      offset,
      execludeClientIdArray,
      orderBy,
    );
    queryString = replaceQueryString({
      json,
      queryString,
    });

    const { data } = await gqlSearch({
      fetchPolicy: 'no-cache',
      query: gql`
        query ${queryString}
      `,
    });

    setQueryResult(data);
  };

  if (!cookies.token) {
    return <Navigate to={url.login} replace={true} />;
  }

  return (
    <MainLayout
      title="Поисковый модуль"
      actionText="Выгрузка в CSV"
      loading={loading || searchResultFileExportApi.loading}
      disabledButton={!!!Object.keys(currentFilterDataToExport)?.length}
      action={() => {
        setDisabled(false);
        setModal(true);
      }}
    >
      <Grid container spacing={2} sx={{ mb: 5 }}>
        <Grid item xs={filterOpen ? 3 : 1}>
          {filterOpen ? (
            <SmallFilter
              closeFilter={() => setFilterOpen(false)}
              patientCount={data?.medcards?.patientCount}
              clearRows={() => {
                setQueryResult({});
              }}
              filterData={filterData}
              searchHandler={(data, execludeClientIdArray, orderBy = []) => {
                setSeacrhFilterData(data);
                setCurrentFilterDataToExport(data);

                setExecludeClientIdArray(execludeClientIdArray);
                setOrderBy(orderBy);
                searchHandler(data, 15, 0, execludeClientIdArray, orderBy);
              }}
              clearFilterExportDatahandler={() =>
                setCurrentFilterDataToExport({})
              }
              openBigFilterHandler={openBigFilterHandler}
              setOpenBigFilterHandler={(data) => setOpenBigFilterHandler(data)}
              loading={loading}
            />
          ) : (
            <Button
              sx={{ height: '70px', width: '70px', background: '#fff', mt: 4 }}
              onClick={() => setFilterOpen(true)}
            >
              <ArrowForwardOutlinedIcon />
            </Button>
          )}
        </Grid>
        <Grid item xs={filterOpen ? 9 : 11}>
          <StickyContainer style={{ height: '100%' }}>
            <Sticky>
              {({ style, distanceFromTop }) => (
                <div style={{ ...style }}>
                  <SearchResult
                    loading={loading}
                    meddocTitle={meddocTitle}
                    rows={queryResult?.medcards?.data}
                    setOpenBigFilterHandler={() => {
                      setOpenBigFilterHandler(true);
                    }}
                    orderByHandler={(orderBy) => {
                      setOrderBy(orderBy);
                      searchHandler(
                        seacrhFilterData,
                        15,
                        (page - 1) * 15,
                        execludeClientIdArray,
                        orderBy,
                      );
                    }}
                  />
                  {!loading && !!queryResult?.medcards?.totalCount && (
                    <Pagination
                      sx={{ mt: 3 }}
                      page={page}
                      count={Math.floor(queryResult?.medcards?.totalCount / 15)}
                      onChange={(event, value) => {
                        setPage(+value);
                        searchHandler(
                          seacrhFilterData,
                          15,
                          (+value - 1) * 15,
                          execludeClientIdArray,
                          orderBy,
                        );
                      }}
                      variant="outlined"
                      shape="rounded"
                    />
                  )}
                </div>
              )}
            </Sticky>
          </StickyContainer>
        </Grid>
        <ActionApprove
          closeAction={() => setModal(false)}
          successAction={() => {
            let queryString = jsonToGraphQlQuery(
              currentFilterDataToExport,
              0,
              0,
            );

            queryString = replaceQueryString({
              json: currentFilterDataToExport,
              queryString,
            });

            searchResultFileExportApi
              .sendRequest({
                graphql_query: `query ${queryString}`,
                file_name: exportData.title,
                userId: user.sub,
              })
              .then((result) => {
                let enum_event = '';
                if (exportData?.purpose == 'Текущая работа') {
                  enum_event = 'DownloadResultWork';
                } else if (exportData?.purpose == 'Научная статья') {
                  enum_event = 'DownloadResultPaper';
                } else if (exportData?.purpose == 'Диссертация') {
                  enum_event = 'DownloadResultDisser';
                }
                CreateEventApi.sendRequest({
                  userId: user.sub,
                  userName:
                    user.family_name && user.given_name && user.middle_name
                      ? user.family_name +
                        ' ' +
                        user.given_name +
                        ' ' +
                        user.middle_name
                      : user.email,
                  role: getUserRole(user.role),
                  text_event:
                    'Скачивание результатов поиска' +
                    exportData?.name +
                    '. Кол-во пациентов - ' +
                    exportData?.patientCount +
                    '. (' +
                    exportData?.purpose +
                    ')',
                  enum_event: enum_event,
                });
                downloadURI(result, exportData.title);
              });

            setModal(false);
          }}
          closeActionButtonText="Отмена"
          open={modal}
          disabledButton={disabled}
          alertMessages={{
            success: 'CSV файл успешно скачен',
            error: '',
          }}
          modalVariant="primary"
          showCloseButton={false}
          successActionButtonText="Скачать"
          successActionButtonColor="primary"
          successActionButtonVariant="contained"
          title="Сохранение файла csv"
        >
          <ExportForm
            state={exportData}
            user={user}
            totalCount={queryResult?.medcards?.patientCount}
            changeState={(data) => {
              setDisabled(!(data?.title && data?.agree));
              setExportData(data);
            }}
          />
        </ActionApprove>
      </Grid>
    </MainLayout>
  );
};
