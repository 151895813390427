import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { getCustomUserRole } from './customeRoleApi';

export const goToOAuth = async (redirectUri) => {
  try {
    const myParams = {
      client_id: 'meddata',
      redirect_uri: redirectUri,
      state: uuidv4(),
      response_mode: 'fragment',
      response_type: 'code',
      scope: 'openid roles',
    };

    const u = new URLSearchParams(myParams).toString();

    window.location = `https://id.isu.sechenov.ru/auth/realms/sechenov/protocol/openid-connect/auth?${u}`;
  } catch (error) {
    throw error;
  }
};

export const getJwtToken = async (code, redirectUri) => {
  try {
    const { data } = await axios.get('https://meddata.sechenov.ru/v1/auth/', {
      params: { code, redirect_uri: redirectUri },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getMe = async (token) => {
  try {
    const { data } = await axios.get(
      'https://id.isu.sechenov.ru/auth/realms/sechenov/protocol/openid-connect/userinfo',
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const role = (await getCustomUserRole(data.sub)) || 'User';

    return { ...data, role };
  } catch (error) {
    throw error;
  }
};

export const logout = async () => {
  try {
    const redirectUri =
      window.location.protocol + '//' + window.location.host + '/';
    window.location = `https://id.isu.sechenov.ru/auth/realms/sechenov/protocol/openid-connect/logout?redirect_uri=${redirectUri}`;
  } catch (error) {
    throw error;
  }
};

export const login = async (dto) => {
  try {
    const body = new URLSearchParams();
    body.append('username', dto.email);
    body.append('password', dto.password);
    body.append('client_id', 'meddata');
    body.append('client_secret', 'VnDjAaWM0z5n08y4l1bXbZuEAiJDeVp1');
    body.append('grant_type', 'password');
    const { data } = await axios.post(
      'https://id.isu.sechenov.ru/auth/realms/sechenov/protocol/openid-connect/token',
      body,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllUsersList = async ({ token }) => {
  try {
    const { data } = await axios.get(
      `https://id.isu.sechenov.ru/auth/realms/meddata/ui-ext/groups`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};

// export const forgotPassword = async ({ email, token }) => {
//   try {
//     const redirectUri =
//       window.location.protocol + '//' + window.location.host + '/';
//     const {
//       data: { id },
//     } = await axios.get();
//     const { data } = await axios.put(
//       `https://id.isu.sechenov.ru/auth/realms/sechenov/users/${id}/execute-actions-email?redirect_uri=${redirectUri}&client_id=meddata`,
//       ['UPDATE_PASSWORD'],
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       },
//     );
//     return data;
//   } catch (error) {
//     throw error;
//   }
// };
