import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import 'rsuite/dist/rsuite.min.css';

import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO } from 'date-fns';
import { gql, useLazyQuery } from '@apollo/client';

import { DateRangePicker } from 'rsuite';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MULTI_SELECT_DEFAULT_DATA_QUERY = gql`
  query MyQuery {
    __typename ## Placeholder value
  }
`;
export const FilterFields = ({
  key,
  value,
  param,
  parentKey,
  focusedSearchKey,
  currentFilterData,
  changeCurrentFilterData,
  apiLoading,
  anyValue,
  disableMe,
}) => {
  const [component, setComponent] = useState(() => <></>);
  const [multiSelectDefaultArray, setMultiSelectDefaultArray] = useState([]);
  const [openSelect, setOpenSelect] = useState(false);
  const changeHandler = (
    anyValue,
    key,
    value,
    graphQlTable,
    graphQlQueryString,
    name,
  ) => {
    let newData = [];

    const paramCheck = currentFilterData?.some(
      (item) => item?.key == param.key,
    );
    if (paramCheck) {
      newData = currentFilterData.map((item) => {
        if (item.key == param.key) {
          return { ...item, graphQlQueryString, value, anyValue };
        }
        return item;
      });
    } else {
      newData = [
        ...currentFilterData,
        {
          key: key,
          value,
          graphQlTable,
          graphQlQueryString,
          name,
          anyValue,
        },
      ];
    }

    changeCurrentFilterData(newData);
  };

  let [gqlSearch, { error, data, loading }] = useLazyQuery(
    MULTI_SELECT_DEFAULT_DATA_QUERY,
  );

  const getMultiSelectDefaultData = async (key) => {
    try {
      const table = key.split('_')[0];
      const field = key.split('_')[1];
      const { data } = await gqlSearch({
        fetchPolicy: 'no-cache',
        query: gql`
        query {
          ${table}{
            ${field}
          }
        }
        `,
      });

      return data[table][field];
    } catch (error) {}
  };
  useMemo(() => {
    if (
      param?.defaultResolverName &&
      openSelect &&
      !multiSelectDefaultArray?.length
    ) {
      getMultiSelectDefaultData(param?.defaultResolverName).then((result) =>
        setMultiSelectDefaultArray(result),
      );
    }
  }, [openSelect]);

  useMemo(() => {
    switch (param?.fieldType) {
      case 'TextField':
        setComponent(
          <>
            <TextField
              key={param.key}
              variant="outlined"
              disabled={apiLoading || disableMe}
              focused={param.key == focusedSearchKey}
              value={value || ''}
              label={param?.name}
              fullWidth
              onChange={({ target: { value } }) => {
                changeHandler(
                  false,
                  param.key,
                  value,
                  param?.graphQlQueryString,
                  {
                    contains: value,
                  },
                  param?.name,
                );
              }}
            />
          </>,
        );
        break;
      case 'NumberField':
        setComponent(
          <TextField
            key={param.key}
            variant="outlined"
            disabled={apiLoading || disableMe}
            focused={param.key == focusedSearchKey}
            value={value || ''}
            label={param?.name}
            fullWidth
            onChange={({ target: { value } }) => {
              changeHandler(
                false,
                param.key,
                value,
                param?.graphQlQueryString,
                { eq: +value },
                param?.name,
              );
            }}
          />,
        );
        break;
      case 'NumberRange':
        setComponent(
          <FormControl fullWidth key={param?.key}>
            <Stack
              key={param.key}
              labelId={`multiple-name-label-${param?.key}`}
              direction="row"
              spacing={2}
            >
              <TextField
                key={'from' + key}
                disabled={apiLoading || disableMe}
                variant="outlined"
                label={param?.name + ' (От)'}
                fullWidth
                focused={param.key == focusedSearchKey}
                value={value?.from || ''}
                onChange={({ target }) => {
                  changeHandler(
                    false,
                    param.key,
                    {
                      to: +value?.to,
                      from: +target.value,
                    },
                    param?.graphQlQueryString,
                    { between: [+target.value || null, +value?.to || null] },
                    param?.name,
                  );
                }}
              />
              <TextField
                key={'to' + param.key}
                disabled={apiLoading || disableMe}
                value={value?.to || ''}
                variant="outlined"
                focused={param.key == focusedSearchKey}
                label={param?.name + ' (До)'}
                fullWidth
                onChange={({ target }) => {
                  changeHandler(
                    false,
                    param.key,
                    {
                      to: +target.value,
                      from: +value?.from,
                    },
                    param?.graphQlQueryString,
                    { between: [+value?.from || null, +target.value || null] },
                    param?.name,
                  );
                }}
              />
            </Stack>
          </FormControl>,
        );
        break;
      case 'DateField':
        setComponent(
          <LocalizationProvider dateAdapter={AdapterDateFns} key={param?.key}>
            <DatePicker
              label={param?.name}
              disabled={apiLoading || disableMe}
              focused={param.key == focusedSearchKey}
              value={value || null}
              sx={{ width: '100%' }}
              onChange={(value) => {
                changeHandler(
                  false,
                  param.key,
                  value,
                  param?.graphQlTable,
                  {
                    eq: format(value, 'yyyy-MM-dd'),
                  },
                  param?.name,
                );
              }}
            />
          </LocalizationProvider>,
        );
        break;
      case 'DateRange':
        setComponent(
          <>
            <DateRangePicker
              locale={{
                sunday: 'вс',
                monday: 'пн',
                tuesday: 'вт',
                wednesday: 'ср',
                thursday: 'чт',
                friday: 'пт',
                saturday: 'сб',
                ok: 'Ок',
                today: 'Сегодня',
                yesterday: 'Вчера',
                hours: 'Час.',
                minutes: 'Мин.',
                seconds: 'Сек.',
                last7Days: 'Последние 7 дней',
              }}
              style={{ top: '-100px' }}
              size="lg"
              placeholder={param?.name}
              disabled={apiLoading || disableMe}
              key={param?.key}
              value={[
                typeof value?.startDate == 'string'
                  ? new Date(parseISO(value?.startDate))
                  : value?.startDate || null,
                typeof value?.endDate == 'string'
                  ? new Date(parseISO(value?.endDate))
                  : value?.endDate || null,
              ]}
              onChange={(dates) => {
                changeHandler(
                  false,
                  param.key,
                  {
                    startDate: dates?.length && dates[0] ? dates[0] : null,
                    endDate: dates?.length && dates[1] ? dates[1] : null,
                  },
                  param?.graphQlTable,
                  {
                    between: [
                      dates?.length && dates[0]
                        ? format(new Date(dates[0]), 'yyyy-MM-dd')
                        : null,
                      dates?.length && dates[1]
                        ? format(new Date(dates[1]), 'yyyy-MM-dd')
                        : null,
                    ],
                  },
                  param?.name,
                );
              }}
            />
          </>,
        );
        break;
      case 'DateTimeField':
        setComponent(
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              focused={param.key == focusedSearchKey}
              disabled={apiLoading || disableMe}
              label={param?.name}
              value={value || null}
              sx={{ width: '100%', mb: 2 }}
              onChange={(value) => {
                changeHandler(
                  false,
                  param.key,
                  value,
                  param?.graphQlTable,
                  {
                    eq: format(value, 'yyyy-MM-dd HH:mm:ss'),
                  },
                  param?.name,
                );
              }}
            />
          </LocalizationProvider>,
        );
        break;
      case 'MultiSelect':
        setComponent(
          <FormControl
            key={param?.key}
            fullWidth
            focused={param.key == focusedSearchKey}
          >
            <InputLabel id={`multiple-name-label-${param?.key}`}>
              {param?.name}
            </InputLabel>
            <Select
              name={param.key}
              focused={param.key == focusedSearchKey}
              disabled={apiLoading || disableMe}
              defaultValue=""
              labelId={`multiple-name-label-${param?.key}`}
              id={`multiple-name-${param?.key}`}
              multiple
              onOpen={() => setOpenSelect(true)}
              onClose={() => setOpenSelect(false)}
              open={openSelect}
              value={value || []}
              onChange={({ target: { value } }) => {
                changeHandler(
                  false,
                  param.key,
                  value,
                  param?.graphQlTable,
                  {
                    in_: value,
                  },
                  param?.name,
                );
              }}
              input={<OutlinedInput label={param?.name} />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {!loading ? (
                multiSelectDefaultArray?.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))
              ) : (
                <CircularProgress />
              )}
            </Select>
          </FormControl>,
        );
        break;
      case 'SingleSelect':
        setComponent(
          param?.key == 'MedicalDocuments_DocTitle' ? (
            <FormControl
              key={param?.key}
              fullWidth
              focused={param.key == focusedSearchKey}
            >
              <InputLabel id={`simple-name-label-${param?.key}`}>
                {param?.name}
              </InputLabel>
              <Select
                name={param.key}
                focused={param.key == focusedSearchKey}
                onOpen={() => setOpenSelect(true)}
                onClose={() => setOpenSelect(false)}
                open={openSelect}
                defaultValue=""
                labelId={`simple-name-label-${param?.key}`}
                id={`simple-name-${param?.key}`}
                disabled={apiLoading || disableMe}
                value={value || ''}
                label={param?.name}
                onChange={({ target: { value } }) => {
                  changeHandler(
                    false,
                    param.key,
                    value,
                    param?.graphQlTable,
                    {
                      eq: value,
                    },
                    param?.name,
                  );
                }}
              >
                {param?.defaultData?.map((item, index) => (
                  <MenuItem key={index} value={item[0]}>
                    {item[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <FormControl
              key={param?.key}
              fullWidth
              focused={param.key == focusedSearchKey}
            >
              <InputLabel id={`simple-name-label-${param?.key}`}>
                {param?.name}
              </InputLabel>
              <Select
                name={param.key}
                focused={param.key == focusedSearchKey}
                onOpen={() => setOpenSelect(true)}
                onClose={() => setOpenSelect(false)}
                open={openSelect}
                defaultValue=""
                labelId={`simple-name-label-${param?.key}`}
                id={`simple-name-${param?.key}`}
                disabled={apiLoading}
                value={value || ''}
                label={param?.name}
                onChange={({ target: { value } }) => {
                  changeHandler(
                    false,
                    param.key,
                    value,
                    param?.graphQlTable,
                    {
                      eq: value,
                    },
                    param?.name,
                  );
                }}
              >
                {multiSelectDefaultArray?.length ? (
                  !loading ? (
                    multiSelectDefaultArray?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))
                  ) : (
                    <CircularProgress />
                  )
                ) : (
                  param?.defaultData?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          ),
        );
        break;
      case 'BooleanSelect':
        setComponent(
          <FormControl
            key={param?.key}
            fullWidth
            focused={param.key == focusedSearchKey}
          >
            <InputLabel id={`simple-name-label-${param?.key}`}>
              {param?.name}
            </InputLabel>
            <Select
              name={param.key}
              defaultValue=""
              onOpen={() => setOpenSelect(true)}
              onClose={() => setOpenSelect(false)}
              open={openSelect}
              focused={param.key == focusedSearchKey}
              labelId={`simple-name-label-${param?.key}`}
              id={`simple-name-${param?.key}`}
              disabled={apiLoading || disableMe}
              value={value || ''}
              label={param?.name}
              onChange={({ target: { value } }) =>
                changeHandler(
                  false,
                  param.key,
                  value,
                  param?.graphQlTable,
                  {
                    eq: value,
                  },
                  param?.name,
                )
              }
            >
              {param?.defaultData?.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item ? 'Да' : 'Нет'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>,
        );
        break;
      default:
        break;
    }
  }, [
    key,
    value,
    param,
    parentKey,
    focusedSearchKey,
    currentFilterData,
    changeCurrentFilterData,
    apiLoading,
    anyValue,
    multiSelectDefaultArray,
    openSelect,
  ]);
  // useEffect(() => {
  //   switch (param?.fieldType) {
  //     case 'TextField':
  //       setComponent(() => (
  //         <TextField
  //           key={param.key}
  //           variant="outlined"
  //           disabled={apiLoading || disableMe}
  //           focused={param.key == focusedSearchKey}
  //           value={value || ''}
  //           label={param?.name}
  //           fullWidth
  //           onChange={({ target: { value } }) => {
  //             changeHandler(
  //               false,
  //               param.key,
  //               value,
  //               param?.graphQlQueryString,
  //               {
  //                 contains: value,
  //               },
  //               param?.name,
  //             );
  //           }}
  //         />
  //       ));
  //       break;
  //     case 'NumberField':
  //       setComponent(() => (
  //         <TextField
  //           key={param.key}
  //           variant="outlined"
  //           disabled={apiLoading || disableMe}
  //           focused={param.key == focusedSearchKey}
  //           value={value || ''}
  //           label={param?.name}
  //           fullWidth
  //           onChange={({ target: { value } }) => {
  //             changeHandler(
  //               false,
  //               param.key,
  //               value,
  //               param?.graphQlQueryString,
  //               { eq: +value },
  //               param?.name,
  //             );
  //           }}
  //         />
  //       ));
  //       break;
  //     case 'NumberRange':
  //       setComponent(() => (
  //         <FormControl fullWidth key={param?.key}>
  //           <Stack
  //             key={param.key}
  //             labelId={`multiple-name-label-${param?.key}`}
  //             direction="row"
  //             spacing={2}
  //           >
  //             <TextField
  //               key={'from' + key}
  //               disabled={apiLoading || disableMe}
  //               variant="outlined"
  //               label={param?.name + ' (От)'}
  //               fullWidth
  //               focused={param.key == focusedSearchKey}
  //               value={value?.from || ''}
  //               onChange={({ target: { value } }) => {
  //                 const cat = currentFilterData?.find(
  //                   (cat) => cat.key == parentKey,
  //                 );
  //                 const p = cat?.searchData?.find(
  //                   (item) => item.key == param.key,
  //                 );

  //                 changeHandler(
  //                   false,
  //                   param.key,
  //                   {
  //                     ...(p?.value || {}),
  //                     from: value,
  //                   },
  //                   param?.graphQlTable,
  //                   param?.name,
  //                 );
  //               }}
  //             />
  //             <TextField
  //               key={'to' + param.key}
  //               disabled={apiLoading || disableMe}
  //               value={value?.to || ''}
  //               variant="outlined"
  //               focused={param.key == focusedSearchKey}
  //               label={param?.name + ' (До)'}
  //               fullWidth
  //               onChange={({ target: { value } }) => {
  //                 const cat = currentFilterData?.find(
  //                   (cat) => cat.key == parentKey,
  //                 );
  //                 const p = cat?.searchData?.find(
  //                   (item) => item.key == param.key,
  //                 );

  //                 changeHandler(
  //                   false,
  //                   param.key,
  //                   {
  //                     ...(p?.value || {}),
  //                     to: value,
  //                   },
  //                   param?.graphQlTable,
  //                   param?.name,
  //                 );
  //               }}
  //             />
  //           </Stack>
  //         </FormControl>
  //       ));
  //       break;
  //     case 'DateField':
  //       setComponent(() => (
  //         <LocalizationProvider dateAdapter={AdapterDateFns} key={param?.key}>
  //           <DatePicker
  //             label={param?.name}
  //             disabled={apiLoading || disableMe}
  //             focused={param.key == focusedSearchKey}
  //             value={value || null}
  //             sx={{ width: '100%' }}
  //             onChange={(value) => {
  //               changeHandler(
  //                 false,
  //                 param.key,
  //                 value,
  //                 param?.graphQlTable,
  //                 {
  //                   eq: format(value, 'yyyy-MM-dd'),
  //                 },
  //                 param?.name,
  //               );
  //             }}
  //           />
  //         </LocalizationProvider>
  //       ));
  //       break;
  //     case 'DateRange':
  //       setComponent(() => (
  //         <DateRangePicker
  //           size="lg"
  //           placeholder={param?.name}
  //           disabled={apiLoading || disableMe}
  //           key={param?.key}
  //           onChange={(dates) =>
  //             changeHandler(
  //               false,
  //               param.key,
  //               {
  //                 startDate: dates?.length && dates[0] ? dates[0] : null,
  //                 endDate: dates?.length && dates[1] ? dates[1] : null,
  //               },
  //               param?.graphQlTable,
  //               {
  //                 between: [
  //                   dates?.length && dates[0]
  //                     ? format(new Date(dates[0]), 'yyyy-MM-dd')
  //                     : null,
  //                   dates?.length && dates[1]
  //                     ? format(new Date(dates[1]), 'yyyy-MM-dd')
  //                     : null,
  //                 ],
  //               },
  //               param?.name,
  //             )
  //           }
  //         />
  //       ));
  //       break;
  //     case 'DateTimeField':
  //       setComponent(() => (
  //         <LocalizationProvider dateAdapter={AdapterDateFns}>
  //           <DateTimePicker
  //             focused={param.key == focusedSearchKey}
  //             disabled={apiLoading || disableMe}
  //             label={param?.name}
  //             value={value || null}
  //             sx={{ width: '100%', mb: 2 }}
  //             onChange={(value) => {
  //               changeHandler(
  //                 false,
  //                 param.key,
  //                 value,
  //                 param?.graphQlTable,
  //                 {
  //                   eq: format(value, 'yyyy-MM-dd HH:mm:ss'),
  //                 },
  //                 param?.name,
  //               );
  //             }}
  //           />
  //         </LocalizationProvider>
  //       ));
  //       break;
  //     case 'MultiSelect':
  //       setComponent(() => (
  //         <FormControl
  //           key={param?.key}
  //           fullWidth
  //           focused={param.key == focusedSearchKey}
  //         >
  //           <InputLabel id={`multiple-name-label-${param?.key}`}>
  //             {param?.name}
  //           </InputLabel>
  //           <Select
  //             focused={param.key == focusedSearchKey}
  //             disabled={apiLoading || disableMe}
  //             labelId={`multiple-name-label-${param?.key}`}
  //             id={`multiple-name-${param?.key}`}
  //             multiple
  //             value={value || []}
  //             onChange={({ target: { value } }) => {
  //               changeHandler(
  //                 false,
  //                 param.key,
  //                 value,
  //                 param?.graphQlTable,
  //                 {
  //                   in_: value,
  //                 },
  //                 param?.name,
  //               );
  //             }}
  //             input={<OutlinedInput label={param?.name} />}
  //             renderValue={(selected) => (
  //               <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
  //                 {selected.map((value) => (
  //                   <Chip key={value} label={value} />
  //                 ))}
  //               </Box>
  //             )}
  //             MenuProps={MenuProps}
  //           >
  //             {!loading ? (
  //               multiSelectDefaultArray?.map((name, index) => (
  //                 <MenuItem key={index} value={name}>
  //                   {name}
  //                 </MenuItem>
  //               ))
  //             ) : (
  //               <CircularProgress />
  //             )}
  //           </Select>
  //         </FormControl>
  //       ));
  //       break;
  //     case 'SingleSelect':
  //       setComponent(() =>
  //         param?.key == 'MedicalDocuments_DocTitle' ? (
  //           <FormControl
  //             key={param?.key}
  //             fullWidth
  //             focused={param.key == focusedSearchKey}
  //           >
  //             <InputLabel id={`simple-name-label-${param?.key}`}>
  //               {param?.name}
  //             </InputLabel>
  //             <Select
  //               focused={param.key == focusedSearchKey}
  //               labelId={`simple-name-label-${param?.key}`}
  //               id={`simple-name-${param?.key}`}
  //               disabled={apiLoading || disableMe}
  //               value={value}
  //               label={param?.name}
  //               onChange={({ target: { value } }) => {
  //                 changeHandler(
  //                   false,
  //                   param.key,
  //                   value,
  //                   param?.graphQlTable,
  //                   {
  //                     eq: value,
  //                   },
  //                   param?.name,
  //                 );
  //               }}
  //             >
  //               {param?.defaultData?.map((item, index) => (
  //                 <MenuItem key={index} value={item[0]}>
  //                   {item[0]}
  //                 </MenuItem>
  //               ))}
  //             </Select>
  //           </FormControl>
  //         ) : (
  //           <FormControl
  //             key={param?.key}
  //             fullWidth
  //             focused={param.key == focusedSearchKey}
  //           >
  //             <InputLabel id={`simple-name-label-${param?.key}`}>
  //               {param?.name}
  //             </InputLabel>
  //             <Select
  //               focused={param.key == focusedSearchKey}
  //               labelId={`simple-name-label-${param?.key}`}
  //               id={`simple-name-${param?.key}`}
  //               disabled={apiLoading}
  //               value={value}
  //               label={param?.name}
  //               onChange={({ target: { value } }) => {
  //                 changeHandler(
  //                   false,
  //                   param.key,
  //                   value,
  //                   param?.graphQlTable,
  //                   {
  //                     eq: value,
  //                   },
  //                   param?.name,
  //                 );
  //               }}
  //             >
  //               {multiSelectDefaultArray?.length ? (
  //                 !loading ? (
  //                   multiSelectDefaultArray?.map((item, index) => (
  //                     <MenuItem key={index} value={item}>
  //                       {item}
  //                     </MenuItem>
  //                   ))
  //                 ) : (
  //                   <CircularProgress />
  //                 )
  //               ) : (
  //                 param?.defaultData?.map((item, index) => (
  //                   <MenuItem key={index} value={item}>
  //                     {item}
  //                   </MenuItem>
  //                 ))
  //               )}
  //             </Select>
  //           </FormControl>
  //         ),
  //       );
  //       break;
  //     case 'BooleanSelect':
  //       setComponent(() => (
  //         <FormControl
  //           key={param?.key}
  //           fullWidth
  //           focused={param.key == focusedSearchKey}
  //         >
  //           <InputLabel id={`simple-name-label-${param?.key}`}>
  //             {param?.name}
  //           </InputLabel>
  //           <Select
  //             focused={param.key == focusedSearchKey}
  //             labelId={`simple-name-label-${param?.key}`}
  //             id={`simple-name-${param?.key}`}
  //             disabled={apiLoading || disableMe}
  //             value={value}
  //             label={param?.name}
  //             onChange={({ target: { value } }) =>
  //               changeHandler(
  //                 false,
  //                 param.key,
  //                 value,
  //                 param?.graphQlTable,
  //                 {
  //                   eq: value,
  //                 },
  //                 param?.name,
  //               )
  //             }
  //           >
  //             {param?.defaultData?.map((item, index) => (
  //               <MenuItem key={index} value={item}>
  //                 {item ? 'Да' : 'Нет'}
  //               </MenuItem>
  //             ))}
  //           </Select>
  //         </FormControl>
  //       ));
  //       break;
  //     default:
  //       break;
  //   }
  // }, [
  //   key,
  //   value,
  //   param,
  //   parentKey,
  //   focusedSearchKey,
  //   currentFilterData,
  //   changeCurrentFilterData,
  //   apiLoading,
  //   anyValue,
  // ]);

  if (param?.information) {
    return (
      <Tooltip title={param?.information}>
        <Stack sx={{ mb: 2 }}>
          {component}
          <FormControlLabel
            sx={{ opacity: '0.7' }}
            control={
              <Checkbox
                size="small"
                checked={anyValue}
                disabled={apiLoading || disableMe}
                onChange={({ target: { checked } }) =>
                  changeHandler(
                    checked,
                    param.key,
                    checked ? null : value,
                    param?.graphQlTable,
                    {
                      eq: value,
                    },
                    param?.name,
                  )
                }
              />
            }
            label="Любое значение"
          />
        </Stack>
      </Tooltip>
    );
  } else {
    return (
      <Box sx={{ mb: 2 }}>
        {component}

        <FormControlLabel
          sx={{ opacity: '0.7' }}
          control={
            <Checkbox
              size="small"
              checked={anyValue}
              disabled={apiLoading || disableMe}
              onChange={({ target: { checked } }) =>
                changeHandler(
                  checked,
                  param.key,
                  checked ? null : value,
                  param?.graphQlTable,
                  {
                    eq: value,
                  },
                  param?.name,
                )
              }
            />
          }
          label="Любое значение"
        />
      </Box>
    );
  }
};
