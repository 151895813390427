import {
  Alert,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { ActionApprove } from '../Modal/ActionApprove';
import { SaveSearchTemplateForm } from '../SaveSearchTemplate/SaveSearchTemplateForm';
import { url } from '../../utils/url';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { removeTemplate } from '../../api/templatesApi';
import { jsonToGraphQlQuery } from '../../utils/jsonToGraphQlQuery';
import {
  getExistDatatable,
  searchResultFileExport,
} from '../../api/fileExportApi';
import { setAlert } from '../../store/alert.store';
import { useDispatch } from 'react-redux';
import { downloadURI } from '../../utils/downloadURI';
import { CreateEvent } from '../../api/adminAnalyticsApi';
import { getUserRole } from '../../utils/getUserRole';

export const AnalyticsExplorerTable = ({
  rows = [],
  removeHandler,
  updateHandler,
  user,
  loading,
}) => {
  const [disabled, setDisabled] = useState(true);
  const searchResultFileExportApi = useApi(searchResultFileExport);
  const getExistDatatableApi = useApi(getExistDatatable);

  useEffect(() => {}, []);
  const [modal, setModal] = useState({
    open: false,
    closeAction: () => null,
    successAction: () => null,
    closeActionButtonText: '',
    successActionButtonText: '',
    title: '',
    successActionButtonColor: 'error',
    children: '',
    successActionButtonVariant: 'outlined',
    modalVariant: 'primary',
    disabledButton: disabled,
    alertMessages: {
      success: '',
      error: '',
    },
    showCloseButton: true,
  });
  const [template, setTemplate] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [currentDownloading, setCurrentDownloading] = useState(null);
  const navigate = useNavigate();
  const CreateEventApi = useApi(CreateEvent);

  const stringSlicer = (text, length = 30) => {
    if (text?.length > length) {
      return <Tooltip title={text}>{text.slice(0, length) + '...'}</Tooltip>;
    } else {
      return text;
    }
  };

  const dispatch = useDispatch();
  return (
    <>
      {!loading ? (
        !!rows?.length ? (
          <TableContainer component={Paper} sx={{ mb: 5 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ maxWidth: '250px' }}>
                    Название шаблона
                  </TableCell>
                  <TableCell sx={{ maxWidth: '250px' }}>Описание</TableCell>
                  <TableCell>Кол-во пациентов</TableCell>
                  <TableCell sx={{ maxWidth: '250px' }}>Тег</TableCell>
                  <TableCell>Дата создания</TableCell>
                  <TableCell>Дата обновления</TableCell>
                  <TableCell>Дата выгрузки</TableCell>

                  <TableCell>Действие</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      sx={{ maxWidth: '250px', cursor: 'pointer' }}
                      component="th"
                      scope="row"
                      onClick={() =>
                        navigate(
                          url.search + '?f=' + JSON.stringify(row.filter),
                        )
                      }
                    >
                      {stringSlicer(row.name)}
                    </TableCell>
                    <TableCell sx={{ maxWidth: '250px' }}>
                      {stringSlicer(row.description)}
                    </TableCell>
                    <TableCell>{row.patientCount}</TableCell>
                    <TableCell sx={{ maxWidth: '250px' }}>
                      {row.purpose}
                    </TableCell>
                    <TableCell>{row.creationDate}</TableCell>
                    <TableCell>{row.updateingDate}</TableCell>
                    <TableCell>{row.lastDownloadingDate || '-'}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        {getExistDatatableApi.loading &&
                        currentDownloading == row.id ? (
                          <CircularProgress />
                        ) : (
                          <IconButton
                            aria-label="edit"
                            color="primary"
                            size="small"
                            onClick={() => {
                              setCurrentDownloading(row.id);
                              getExistDatatableApi
                                .sendRequest({
                                  userId: user.sub,
                                  fileDbId: row.id,
                                  file_name: row.name,
                                  graphql_query: `query ${jsonToGraphQlQuery(
                                    row.filter,
                                    0,
                                    0,
                                  )}`,
                                })
                                .then((result) => {
                                  let enum_event = '';
                                  if (row?.purpose == 'Текущая работа') {
                                    enum_event = 'DownloadResultWork';
                                  } else if (row?.purpose == 'Научная статья') {
                                    enum_event = 'DownloadResultPaper';
                                  } else if (row?.purpose == 'Диссертация') {
                                    enum_event = 'DownloadResultDisser';
                                  }
                                  CreateEventApi.sendRequest({
                                    userId: user.sub,
                                    userName:
                                      user.family_name &&
                                      user.given_name &&
                                      user.middle_name
                                        ? user.family_name +
                                          ' ' +
                                          user.given_name +
                                          ' ' +
                                          user.middle_name
                                        : user.email,
                                    role: getUserRole(user.role),
                                    text_event:
                                      'Скачивание результатов поиска' +
                                      row?.name +
                                      '. Кол-во пациентов - ' +
                                      row?.patientCount +
                                      '. (' +
                                      row?.purpose +
                                      ')',
                                    enum_event: enum_event,
                                  });
                                  downloadURI(result, row.name);
                                  setCurrentDownloading(null);
                                  dispatch(
                                    setAlert({
                                      text: 'Шаблон успешно скачен',
                                      status: 200,
                                    }),
                                  );
                                });
                            }}
                          >
                            <DownloadOutlinedIcon />
                          </IconButton>
                        )}

                        <IconButton
                          aria-label="edit"
                          color="primary"
                          size="small"
                          onClick={() => {
                            setDisabled(
                              !(
                                (template?.name && template?.description) ||
                                (row?.name && row?.description)
                              ),
                            );
                            setTemplate(row);
                            setEditModal(true);
                          }}
                        >
                          <AppRegistrationOutlinedIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          color="error"
                          size="small"
                          onClick={() => {
                            setDisabled(false);
                            setModal({
                              closeAction: () =>
                                setModal({ ...modal, open: false }),
                              successAction: () => {
                                // save template rest api
                                removeHandler(row.id);

                                setModal({ ...modal, open: false });
                              },
                              showCloseButton: true,
                              modalVariant: 'standart',
                              open: true,
                              children: (
                                <Alert color="warning">
                                  Шаблон поиска будет удален без возможности
                                  восстановления
                                </Alert>
                              ),
                              alertMessages: {
                                success: 'Шаблон успешно удален',
                                error: '',
                              },
                              successActionButtonText: 'Удалить',
                              closeActionButtonText: 'Отмена',
                              successActionButtonColor: 'secondary',
                              successActionButtonVariant: 'contained',
                              title: 'Удаление шаблона поиска - ' + row.name,
                            });
                          }}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography
            sx={{
              textAlign: 'center',
              width: '550px',
              display: 'block',
              mx: 'auto',
              py: 4,
            }}
          >
            У вас пока нет сохраненных шаблонов поиска. Чтобы создать шаблон
            перейдите в раздел{' '}
            <span
              style={{ color: '#3e4cad', cursor: 'pointer' }}
              onClick={() => {
                navigate(url.search);
              }}
            >
              поисковый модуль
            </span>
          </Typography>
        )
      ) : (
        <CircularProgress />
      )}
      <ActionApprove
        closeAction={() => {
          setTemplate({});
          setEditModal(false);
        }}
        successAction={() => {
          // save template rest api
          updateHandler({
            ...template,
            file_name: template.name,
            graphql_query: `query ${jsonToGraphQlQuery(template.filter, 0, 0)}`,
          });
          setTemplate({});
          setEditModal(false);
        }}
        closeActionButtonText=""
        showCloseButton={false}
        modalVariant="primary"
        open={editModal}
        alertMessages={{
          success: 'Шаблон успешно обновлен',
          error: '',
        }}
        successActionButtonText="Обновить"
        successActionButtonColor="secondary"
        successActionButtonVariant="contained"
        title={'Обновление шаблона - ' + template?.name}
      >
        <SaveSearchTemplateForm
          state={template}
          changeState={(data) => {
            setDisabled(!(data?.name && data?.description));
            setTemplate({ ...template, ...data });
          }}
        />
      </ActionApprove>
      <ActionApprove
        open={modal.open}
        successAction={modal.successAction}
        closeAction={modal.closeAction}
        successActionButtonText={modal.successActionButtonText}
        closeActionButtonText={modal.closeActionButtonText}
        successActionButtonColor={modal.successActionButtonColor}
        title={modal.title}
        successActionButtonVariant={modal.successActionButtonVariant}
        modalVariant={modal.modalVariant}
        children={modal.children}
        showCloseButton={modal.showCloseButton}
        alertMessages={modal.alertMessages}
        disabledButton={disabled}
      />
    </>
  );
};
