import { Button, Divider, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { getJwtToken, getMe, goToOAuth, login } from '../api/sechenovIdApi';
import { useApi } from '../hooks/useApi';
import { Link, Navigate } from 'react-router-dom';
import { url } from '../utils/url';
import { useDispatch } from 'react-redux';
import { add } from 'date-fns';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { LoadingButton } from '@mui/lab';
import { setAlert } from '../store/alert.store';
import { AuthLayout } from '../components/Layout/AuthLayout/AuthLayout';
import { CreateEvent } from '../api/adminAnalyticsApi';
import { getUserRole } from '../utils/getUserRole';

export const LoginPage = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [rendered, setRendered] = useState(false);
  const [form, setForm] = useState({});
  const sechenovApi = useApi(getJwtToken);
  const getMeApi = useApi(getMe);
  const loginApi = useApi(login);
  const CreateEventApi = useApi(CreateEvent);
  const dispatch = useDispatch();
  const redirectUri =
    window.location.protocol + '//' + window.location.host + '/';
  const code = window.location.href
    ?.split('&')
    ?.map((s) => ({ [s.split('=')[0]]: s.split('=')[1] }))
    ?.find((param) => param.code)?.code;

  useEffect(() => {
    if (code && !rendered) {
      sechenovApi.sendRequest(code, redirectUri).then((result) => {
        if (result?.access_token) {
          getMeApi
            .sendRequest(result?.access_token)
            .then((response) => {
              CreateEventApi.sendRequest({
                userId: response.sub,
                userName:
                  response.given_name &&
                  response.family_name &&
                  response.middle_name
                    ? response.family_name +
                      ' ' +
                      response.given_name +
                      ' ' +
                      response.middle_name
                    : response.email,
                role: getUserRole(response.role),
                text_event: 'Авторизация через Sechenov ID',
                enum_event: 'SechenovIdAuth',
              });
              setCookie('token', result.access_token, {
                expires: add(new Date(), {
                  hours: 24,
                }),
              });
            })
            .catch((error) => {
              console.log({ error });
              dispatch(
                setAlert({
                  text: 'При авторизации произошла ошибка',
                  status: 401,
                }),
              );
            });
        }
      });
    }
    setRendered(true);
  }, [code]);

  const loginHandler = async () => {
    try {
      const result = await loginApi.sendRequest(form);
      const response = await getMeApi.sendRequest(result?.access_token);

      CreateEventApi.sendRequest({
        userId: response.sub,
        userName:
          response.given_name && response.family_name && response.middle_name
            ? response.family_name +
              ' ' +
              response.given_name +
              ' ' +
              response.middle_name
            : response.email,
        role: getUserRole(response.role),
        text_event: 'Авторизация через логин и пароль',
        enum_event: 'DefaultAuth',
      });
      setCookie('token', result.access_token, {
        expires: add(new Date(), {
          hours: 24,
        }),
      });
    } catch (error) {
      dispatch(
        setAlert({
          text: 'При авторизации произошла ошибка',
          status: error.status,
        }),
      );
    }
  };
  if (cookies.token) {
    return <Navigate to={url.search} replace={true} />;
  }
  return (
    <AuthLayout title="Добро пожаловать!" seoTitle="Вход">
      <Button
        color="primary"
        size="large"
        variant="outlined"
        sx={{ mt: 5 }}
        onClick={() => goToOAuth(redirectUri)}
      >
        <img
          src="/logo-light.png"
          style={{ width: '40px', height: '40px', marginRight: '15px' }}
        />
        Авторизация через Sechenov ID
      </Button>
      <Divider sx={{ my: 5 }} />
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonOutlinedIcon />
            </InputAdornment>
          ),
        }}
        onChange={({ target: { value } }) => setForm({ ...form, email: value })}
        disabled={loginApi?.loading}
        variant="outlined"
        label="Email"
        sx={{ mb: 3 }}
      />
      <TextField
        disabled={loginApi?.loading}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HttpsOutlinedIcon />
            </InputAdornment>
          ),
        }}
        onChange={({ target: { value } }) =>
          setForm({ ...form, password: value })
        }
        type="password"
        variant="outlined"
        label="Пароль"
        sx={{ mb: 3 }}
      />
      {/* <Link
        href="/"
        style={{
          color: '#2A3DC0',
          textDecoration: 'none',
          textAlign: 'right',
        }}
      >
        Забыли пароль?
      </Link> */}
      <LoadingButton
        color="primary"
        size="large"
        loading={loginApi?.loading}
        variant="contained"
        sx={{ mt: 5 }}
        disabled={!form?.email || !form?.password}
        onClick={() => loginHandler()}
      >
        Войти
      </LoadingButton>
    </AuthLayout>
  );
};
