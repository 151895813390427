import {
  CircularProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../../hooks/useApi';
import { EventsGetAll } from '../../../api/adminAnalyticsApi';
import { add, format, parseISO } from 'date-fns';

export const AdminEventsStatistics = () => {
  const [events, setEvents] = useState([]);

  const [page, setPage] = useState(1);

  const [totalCount, setTotalCount] = useState(0);

  const EventsGetAllApi = useApi(EventsGetAll);

  const getAllEventssHandler = async (filters = {}) => {
    const result = await EventsGetAllApi.sendRequest({
      limit: 15,
      offset: page - 1,
      ...filters,
    });
    setEvents(result?.data);
    setTotalCount(result?.totalCount);
  };

  useEffect(() => {
    getAllEventssHandler();
  }, [page]);

  const searchUserHandler = async (userName) => {
    getAllEventssHandler(userName ? { filters: { userName } } : {}, 0);
  };
  return (
    <>
      <TextField
        variant="outlined"
        sx={{ mb: 3 }}
        label="Поиск пользователя"
        fullWidth
        onChange={({ target: { value } }) => searchUserHandler(value)}
      />
      {!EventsGetAllApi?.loading ? (
        <>
          <Paper>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Пользователь </TableCell>
                  <TableCell align="left">Роль</TableCell>
                  <TableCell align="left">Событие</TableCell>
                  <TableCell align="left">Дата события</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events?.map((row) => (
                  <TableRow
                    key={row.userName}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">{row.userName}</TableCell>

                    <TableCell component="th" scope="row">
                      {row?.role}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row?.text_event}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {format(
                        add(parseISO(row?.datetime_event), {
                          hours: 3,
                        }),
                        'dd.MM.yyyy HH:mm:ss',
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>

          <Pagination
            sx={{ mt: 3 }}
            count={Math.ceil(totalCount / 15) || 1}
            onChange={(event, value) => {
              setPage(value);
            }}
            variant="outlined"
            shape="rounded"
          />
        </>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};
