import {
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { url } from '../../../utils/url';
import { AlertsList } from '../../AlertsList/AlertsList';
import { useSelector } from 'react-redux';

export const AuthLayout = ({ title, seoTitle, children }) => {
  const alerts = useSelector((state) => state.alerts);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  if (cookies.token) {
    return <Navigate to={url.search} replace={true} />;
  }

  return (
    <Container sx={{ height: '102vh', background: '#fff' }} maxWidth="xl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>С.У. - {seoTitle}</title>

        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      </Helmet>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={6}>
          <img
            src="/logo-light.png"
            style={{ width: '65px', height: '65px', margin: '15px' }}
          />
          <Stack sx={{ maxWidth: '450px', margin: '30px auto' }}>
            <Typography as="h1" variant="h5" sx={{ textAlign: 'center' }}>
              {title}
            </Typography>
            {children}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Card
            sx={{
              width: 'calc(100% - 40px)',
              height: 'calc(100% - 40px)',
              border: 'none',
              boxShadow: 'none',
              padding: '20px',
              mt: 5,
            }}
          >
            <CardContent
              sx={{
                width: 'calc(100% - 40px)',
                height: 'calc(100% - 40px)',
                borderRadius: '30px',
                background: 'url(/login-preview.png)',
                backgroundSize: 'cover',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src="/logo-light-white.png"
                style={{ width: '150px', height: '150px' }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <AlertsList alerts={alerts} />
    </Container>
  );
};
