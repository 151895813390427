import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import filterDataJson from '../Filter/filter.json';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

export const SearchResult = ({
  rows,
  setOpenBigFilterHandler,
  loading,
  meddocTitle,
  orderByHandler,
}) => {
  const filterData = useMemo(() => filterDataJson);
  const [topOffset, setTopOffset] = useState(0);
  const [tableRows, setTableRows] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [orderSelectedKey, setOrderSelectedKey] = useState('');
  const [order, setOrder] = useState(true);

  useEffect(() => {
    if (rows?.length) {
      const rowMapAsync = async () => {
        function keys(row) {
          const a = [];
          void (function foo(d, key) {
            if (d) {
              for (let i in d) {
                if (typeof d[i] == 'object') {
                  foo(d[i], key ? key + '_' + i : i);
                } else {
                  a.push({ key: key ? key + '_' + i : i, value: d[i] || '-' });
                }
              }
            } else {
              a.push({ key, value: '-' });
            }
          })(row);
          return a;
        }

        function remapRows(rows) {
          let result = [];
          for (const row of rows) {
            result.push(keys(row));
          }
          const res = [];
          for (const rs of result) {
            const item = {};

            for (const r of rs) {
              item[r.key] = r.value || '-';
            }
            res.push(item);
          }
          return res;
        }

        rows = remapRows(rows);
        let headers = []
          .concat(
            ...JSON.parse(JSON.stringify(filterData)).map((fd) => fd.params),
          )
          .map((item) => ({
            key: item.key,
            name: item?.name,
          }));

        const thead = [];
        for (const key of Object.keys(rows[0])) {
          if (key == 'ID') {
            console.log({ key: key });
            thead.push({
              key: key,
              name: 'ID истории болезени',
            });
          } else {
            thead.push({
              key: key,
              name:
                headers?.find(
                  (h) =>
                    h.key?.replace('<название анализа>', meddocTitle) == key,
                )?.name || 'Без имени',
            });
          }
        }

        return [thead, rows];
      };
      rowMapAsync().then((response) => {
        setTableRows(response[1]);
        setTableHeaders(response[0]);
      });
    }
  }, [rows]);
  return (
    <>
      <Paper sx={{ mt: 4 }}>
        <Box sx={{ p: 2 }}>
          <Typography as="p" variant="h6">
            Результат поиска
          </Typography>
        </Box>
        <Divider />
        {loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '20px 0',
              width: '100%',
            }}
          >
            <CircularProgress />
          </div>
        ) : !!tableRows?.length ? (
          <>
            <TableContainer sx={{ height: '75vh' }}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {tableHeaders?.map((h, i) => (
                      <TableCell key={i} sx={{ whiteSpace: 'nowrap' }}>
                        {h?.name || '-'}
                        <IconButton
                          onClick={() => {
                            setOrder(!order);
                            setOrderSelectedKey(h?.key);
                            orderByHandler([
                              `${h?.key} ${!order ? 'asc' : 'desc'}`,
                            ]);
                          }}
                        >
                          {orderSelectedKey == h?.key && order ? (
                            <KeyboardArrowUpOutlinedIcon />
                          ) : (
                            <KeyboardArrowDownOutlinedIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {tableRows?.map((row, i) => (
                    <TableRow>
                      {tableHeaders?.map((r) => (
                        <TableCell key={i} sx={{ whiteSpace: 'nowrap' }}>
                          {row[r.key] || '-'}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Typography
            sx={{
              textAlign: 'center',
              width: '550px',
              display: 'block',
              mx: 'auto',
              py: 4,
            }}
          >
            Выберите в левой области фильтра параметры для отображения
            результата или перейдите в{' '}
            <span
              style={{ color: '#3e4cad', cursor: 'pointer' }}
              onClick={() => {
                setOpenBigFilterHandler();
              }}
            >
              расширенный фильтр
            </span>
          </Typography>
        )}
      </Paper>
    </>
  );
};
