import { jsonToGraphQLQuery } from 'json-to-graphql-query';

export const jsonToGraphQlQuery = (
  json = [
    {
      anyValue: false,
      key: '', // Название поля в базе данных
      value: '', // Значение параметра
      graphQlTable: '', // Название таблицы в базе данных для join'ов
      graphQlQueryString: '',
      name: '', // Человеко читаемое название поля
    },
  ],
  limit = 10,
  offset = 0,
  execludeClientIdArray = [],
  orderBy = [],
) => {
  let finalJson = [];
  let patientCode = {};
  for (const item of json) {
    if (
      item?.anyValue ||
      Object.values(item.graphQlQueryString)?.filter((i) => i)?.length
    ) {
      let q;

      if (item.key?.indexOf('_') == -1) {
        q =
          item.key +
          '=' +
          (item?.anyValue ||
          !Object.values(item.graphQlQueryString)?.some((v) => v)
            ? true
            : JSON.stringify({
                __args: {
                  filter: item.graphQlQueryString,
                },
              }));
      } else {
        const splitKey = item.key.split('_');
        const param = splitKey.pop();
        const tables = splitKey;

        q =
          tables.join('.') +
          '.' +
          param +
          '=' +
          (item?.anyValue ||
          !Object.values(item.graphQlQueryString)?.some((v) => v)
            ? true
            : JSON.stringify({ __args: { filter: item.graphQlQueryString } }));
      }
      let data = {};
      q.split('&').forEach((part) => {
        let [path, value] = part.split('=');

        let pointer = data;
        path.split('.').forEach((part, i, arr) => {
          if (!pointer[part])
            pointer[part] = i === arr.length - 1 ? JSON.parse(value) : {};

          pointer = pointer[part];
        });
      });
      finalJson = [...finalJson, data];
    }
  }

  let objRes = {};
  const reccurent = (obj, resultObj) => {
    for (const i in obj) {
      if (resultObj[i]) {
        return reccurent(obj[i], resultObj[i]);
      } else {
        resultObj[i] = obj[i];
        return resultObj;
      }
    }

    return resultObj;
  };
  finalJson = finalJson?.filter((item) => Object.keys(item)[0]);
  for (const obj of finalJson) {
    reccurent(obj, objRes);
  }

  if (execludeClientIdArray.length) {
    objRes = {
      ...objRes,
      PatientCode: {
        __args: {
          filter: {
            ...(objRes?.PatientCode?.__args?.filter || {}),
            notin: execludeClientIdArray,
          },
        },
      },
    };
  }
  console.log({
    ID: true,
    ...objRes,
  });
  const query = {
    MyQuery: {
      medcards: {
        __args: {
          limit: limit,
          offset: offset,
          orderBy: orderBy,
        },
        data: {
          ID: true,
          ...objRes,
        },
        totalCount: true,
        patientCount: true,
      },
    },
  };
  const graphqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return graphqlQuery;
};
