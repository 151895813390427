import axios from 'axios';

export const getCustomUserRole = async (userId) => {
  try {
    const { data } = await axios.get(
      `https://meddata.sechenov.ru/v1/auth/role/${userId}`,
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};
