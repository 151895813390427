import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import React, { useState } from 'react';
import { FilterFields } from './FilterFields';
import { v4 as uuidv4 } from 'uuid';
export const BigFilter = ({
  open,
  closeAction,
  styles,
  buttons,
  otherFiltersFields,
  filterData,
  currentFilterData,
  setCurrentFilterData,

  renderedFilter,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchInFilterString, setSearchInFilterString] = useState('');
  const [searchInFilterResult, setSearchInFilterResult] = useState([]);
  const [selectedSearchItem, setSelectedSearchItem] = useState('');
  const [showSearchResult, setShowSearchResult] = useState(false);
  const filterParams = () => {
    const parent = filterData?.find((item, index) => index == selectedTab);

    return parent.params.map((param, index) => (
      <Grid item xs={3} key={param?.key + index}>
        <div style={{ position: 'relative' }}>
          <FilterFields
            key={param?.key}
            value={currentFilterData?.find((i) => i.key == param.key)?.value}
            anyValue={
              currentFilterData?.find((i) => i.key == param.key)?.anyValue
            }
            param={param}
            focusedSearchKey={selectedSearchItem}
            parentKey={parent?.key}
            currentFilterData={currentFilterData}
            changeCurrentFilterData={(data) => setCurrentFilterData(data)}
            disableMe={
              param.key?.indexOf('<название анализа>') != -1 &&
              !currentFilterData.some(
                (cd) => cd?.key == 'MedicalDocuments_DocTitle',
              )
            }
          />
        </div>
      </Grid>
    ));
  };
  return (
    <Box className={styles.BigFilter__Box}>
      {renderedFilter ? (
        <div className={styles.BigFilter__Box__Content}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Typography variant="h5" as="p">
              Расширенный фильтр
            </Typography>
            <IconButton aria-label="close" onClick={closeAction}>
              <ClearOutlinedIcon />
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <Stack sx={{ width: '100%', position: 'relative' }}>
              <TextField
                id="input-with-icon-adornment"
                label="Поиск по фильтру"
                size="medium"
                fullWidth
                value={searchInFilterString}
                onChange={({ target: { value } }) => {
                  setSearchInFilterString(value);
                  setShowSearchResult(true);
                  const paramsArrayInArray = filterData.map((f, i) =>
                    f.params?.map((p) => ({
                      ...p,
                      parentIndex: i,
                      parentName: f.name,
                    })),
                  );
                  const params = [].concat(...paramsArrayInArray);

                  setSearchInFilterResult(
                    params?.filter(
                      (p) =>
                        p?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) !=
                        -1,
                    ),
                  );
                }}
              />
              {searchInFilterString && showSearchResult && (
                <div className={styles.BigFilter__SearchInFilterResult}>
                  {searchInFilterResult?.map((item) => (
                    <p
                      onClick={() => {
                        setShowSearchResult(false);
                        setSelectedSearchItem(item?.key);
                        setSelectedTab(item?.parentIndex);
                      }}
                    >{`${item?.parentName} - ${item?.name}`}</p>
                  ))}
                </div>
              )}
            </Stack>

            <Stack direction="row" spacing={2} sx={{ width: '65%' }}>
              {buttons({ fullWidth: false, size: 'large' }).reverse()}
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            {otherFiltersFields().map((item) => item)}
          </Stack>
          <Tabs
            onChange={(event, value) => setSelectedTab(value)}
            aria-label="lab API tabs example"
            value={selectedTab}
          >
            {filterData?.map((cat, index) => (
              <Tab
                key={cat?.name + index}
                label={cat?.name}
                value={index}
                id={`simple-tab-${index}`}
                aria-controls={`simple-tabpanel-${index}`}
              />
            ))}
          </Tabs>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            {filterParams()}
          </Grid>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </Box>
  );
};
