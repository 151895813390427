import React from 'react';

import ReactDOM from 'react-dom/client';
import store from './store/store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://8158b077618c69caacdb1d6080aa0b23@o4506438517915648.ingest.sentry.io/4506438534561792',

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [new Sentry.Replay()],
});

const root = ReactDOM.createRoot(document.getElementById('root'));
// const rootRender = () =>
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
// AuthService.initKeycloak(rootRender);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
