import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout/MainLayout';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { AdminGeneralStatistics } from '../components/Admin/AdminGeneralStatistics/AdminGeneralStatistics';
import { useApi } from '../hooks/useApi';
import {
  ActionsGetTable,
  AggStatsGetAll,
  AggStatsGetTable,
  AggStatsYears,
  CreateEvent,
  EventsGetTable,
} from '../api/adminAnalyticsApi';
import { downloadURI } from '../utils/downloadURI';
import { AdminActionsStatisticsTable } from '../components/Admin/AdminActionsStatisticsTable/AdminActionsStatisticsTable';
import { AdminEventsStatistics } from '../components/Admin/AdminEventsStatistics/AdminEventsStatistics';
import { getMe } from '../api/sechenovIdApi';
import { useCookies } from 'react-cookie';
import { url } from '../utils/url';
import { useNavigate } from 'react-router-dom';
import { getUserRole } from '../utils/getUserRole';

export const AdminAnalyticsPage = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  const [user, setUser] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies?.token) {
      userApi.sendRequest(cookies.token).then((result) => {
        if (result.role == 'User') {
          navigate(url.search);
        }
        setUser(result);
      });
    }
  }, []);
  const AggStatsGetTableApi = useApi(AggStatsGetTable);
  const ActionsGetTableApi = useApi(ActionsGetTable);
  const EventsGetTableApi = useApi(EventsGetTable);
  const userApi = useApi(getMe);
  const CreateEventApi = useApi(CreateEvent);

  const changeActiveTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <MainLayout
      title="Статистика"
      actionText="Выгрузка в CSV"
      loading={
        AggStatsGetTableApi.loading ||
        ActionsGetTableApi.loading ||
        EventsGetTableApi.loading
      }
      disabledButton={false}
      action={() => {
        if (activeTab == 1) {
          AggStatsGetTableApi.sendRequest().then((result) => {
            downloadURI(result, new Date());
            CreateEventApi.sendRequest({
              userId: user.sub,
              userName:
                user.family_name && user.given_name && user.middle_name
                  ? user.family_name +
                    ' ' +
                    user.given_name +
                    ' ' +
                    user.middle_name
                  : user.email,
              role: getUserRole(user.role),
              text_event: 'Выгрузка общей статистики действий',
              enum_event: 'DownloadAgrActions',
            });
          });
        } else if (activeTab == 2) {
          ActionsGetTableApi.sendRequest().then((result) => {
            downloadURI(result, new Date());
            CreateEventApi.sendRequest({
              userId: user.sub,
              userName:
                user.family_name && user.given_name && user.middle_name
                  ? user.family_name +
                    ' ' +
                    user.given_name +
                    ' ' +
                    user.middle_name
                  : user.email,
              role: getUserRole(user.role),
              text_event: 'Выгрузка статистики действий по годам',
              enum_event: 'DownloadPersonAgrActions',
            });
          });
        } else if (activeTab == 3) {
          EventsGetTableApi.sendRequest().then((result) => {
            downloadURI(result, new Date());

            CreateEventApi.sendRequest({
              userId: user.sub,
              userName:
                user.family_name && user.given_name && user.middle_name
                  ? user.family_name +
                    ' ' +
                    user.given_name +
                    ' ' +
                    user.middle_name
                  : user.email,
              role: getUserRole(user.role),
              text_event: 'Выгрузка списка пользовательских событий',
              enum_event: 'DownloadAgrActions',
            });
          });
        }
      }}
    >
      <TabContext value={activeTab} sx={{ width: '100%' }}>
        <TabList
          onChange={changeActiveTab}
          aria-label="lab API tabs example"
          sx={{ width: '100%' }}
        >
          <Tab label="Общая статистика" value="1" />
          <Tab label="Действия" value="2" />
          <Tab label="События" value="3" />
        </TabList>
        <TabPanel value="1" sx={{ px: 0 }}>
          <AdminGeneralStatistics />
        </TabPanel>
        <TabPanel value="2" sx={{ px: 0 }}>
          <AdminActionsStatisticsTable />
        </TabPanel>
        <TabPanel value="3" sx={{ px: 0 }}>
          <AdminEventsStatistics />
        </TabPanel>
      </TabContext>
    </MainLayout>
  );
};
